/** @module GenericPageGenericPageContext
 *  @since 2022.02.18, 17:25
 *  @changed 2022.02.18, 17:25
 */

import * as React from 'react'

export interface TGenericPageContext {
  isDark: boolean
}

const defaultGenericPageContext: TGenericPageContext = {
  isDark: false,
}

export const GenericPageContext = React.createContext(defaultGenericPageContext)

export default GenericPageContext
