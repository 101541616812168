/** @module useScroll
 *  @desc Application page wrapper.
 *  @since 2022.01.29, 20:27
 *  @changed 2022.01.29, 21:20
 */

import { useState, useEffect, useCallback } from 'react'
import {
  // throttle,
  debounce,
} from 'throttle-debounce'

const delay = 100

export function useScroll() {
  // Is server-side render?
  if (!process.browser) {
    return {
      scrollY: 0,
      scrollX: 0,
      scrollDirection: null,
    }
  }
  const [state, setState] = useState({
    scrollY: document.body.getBoundingClientRect().top,
    scrollX: document.body.getBoundingClientRect().left,
    scrollDirection: '', // down, up
  })
  const handleScroll = useCallback((_ev) => {
    setState((prevState) => {
      const prevLastScrollTop = prevState.scrollY
      const bodyOffset = document.body.getBoundingClientRect()
      const scrollY = -bodyOffset.top
      const scrollX = bodyOffset.left
      const scrollDirection = prevLastScrollTop > -bodyOffset.top ? 'down' : 'up'
      const newState = {
        // setBodyOffset: bodyOffset,
        scrollY,
        scrollX,
        scrollDirection,
        // lastScrollTop: scrollY,
      }
      // console.log('@:useScroll:handleScroll', {
      //   scrollY,
      //   bodyOffset,
      //   newState,
      // })
      return newState
    })
  }, [])
  useEffect(() => {
    const scrollListener = debounce(delay, (e) => {
      handleScroll(e)
    }) // console.log('useScroll:useEffect')
    window.addEventListener('scroll', scrollListener)
    return () => {
      window.removeEventListener('scroll', scrollListener)
    }
  }, [handleScroll])

  return state
}
