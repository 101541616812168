/** @module OrderCallbackForm
 *  @since 2022.02.02, 22:05
 *  @changed 2022.02.02, 22:06
 */

import React from 'react'
import config from '@config'
import BasicForm from '@forms-support/BasicForm'

export const title = 'Заказать обратный звонок'

// import styles from './OrderCallbackForm.module.less'
// const styles: Record<string, any> = {} // Stub for module styles

const collectionId = 'OrderCallback'
const successText = 'Спасибо, мы перезвоним вам в ближайшее время'
const successTitle = 'Заявка отправлена!'
const introText = 'Заполните форму ниже, и мы обязательно Вам перезвоним'

interface TOrderCallbackFormProps {
  onSuccess?: () => void
}

function OrderCallbackForm(props: TOrderCallbackFormProps) {
  const { onSuccess } = props
  const isDebug = config.build.isDevServer
  const fields = [
    {
      id: 'FirstName',
      name: 'Имя',
      isRequired: true,
      defaultValue: isDebug ? 'test' : '',
    },
    {
      id: 'LastName',
      name: 'Фамилия',
      // isRequired: true,
      // defaultValue: isDebug ? 'test' : '',
    },
    {
      id: 'PhoneNumber',
      name: 'Номер телефона',
      isRequired: true,
      defaultValue: isDebug ? '+7 (123) 456-7890' : '',
    },
    {
      id: 'ConvenientTime',
      name: 'Удобное для звонка время',
      // isRequired: true,
      // defaultValue: isDebug ? 'test' : '',
    },
  ]
  // console.log('@:OrderCallbackForm:OrderCallbackForm', {
  //   collectionId,
  //   fields,
  //   successText,
  //   introText,
  // })
  const formProps = {
    collectionId,
    fields,
    successTitle,
    successText,
    introText,
    onSuccess,
  }
  return <BasicForm {...formProps} />
}

export default OrderCallbackForm
