/** @module HamburgerBox
 *  @since 2022.01.29, 21:48
 *  @changed 2022.02.10, 23:08
 */

import React from 'react'
import classnames from 'classnames'
import useStore, { TState } from '@global/store'
import { Box } from '@chakra-ui/react'
import HamburgerMenu from 'react-hamburger-menu'

import styles from './HamburgerBox.module.less'

interface THamburgerBoxProps {
  className?: string
  handleNavLinkClick?: () => void
  onDark?: boolean
}

function noop() {
  /*NOOP*/
}

function HamburgerBox({ onDark, className, handleNavLinkClick }: THamburgerBoxProps) {
  const isNavOverlayOpen = useStore((state: TState) => state.isNavOverlayOpen)
  const rootClassName = classnames(className, styles.root, onDark && styles.OnDark)
  return (
    <Box className={rootClassName} p={5} onClick={handleNavLinkClick} tabIndex={-1}>
      <HamburgerMenu
        className={classnames(styles.HamburgerMenu, isNavOverlayOpen && styles.HamburgerMenuOpen)}
        isOpen={false}
        menuClicked={noop}
        // menuClicked={handleNavLinkClick}
        height={16}
        width={24}
        strokeWidth={2}
        rotate={180}
      />
    </Box>
  )
}

export default HamburgerBox
