/** @module RequestDetailsForm
 *  @since 2022.02.11, 23:30
 *  @changed 2022.02.12, 00:07
 */

import React from 'react'
import config from '@config'
import BasicForm from '@forms-support/BasicForm'
import { Text } from '@chakra-ui/react'
import { TReactContent } from '@utils/react-types'
import PageLink from '@ui-support/PageLink'

export const title = 'Хотите узнать подробнее?'

export const description: TReactContent = (
  <>
    <Text>Наши менеджеры ответят на все ваши вопросы.</Text>
    <Text>
      Заполните форму или позвоните по номеру{' '}
      <PageLink href={'tel:' + config.site.phoneNumber}>{config.site.phoneNumber}</PageLink>.
    </Text>
  </>
)

const collectionId = 'RequestDetails'
const successText = 'Спасибо, Ваша заявка принята'

function getFileds({ FromPage, isDebug }) {
  return [
    {
      id: 'FromPage',
      name: 'Слайд',
      isHidden: true,
      // isRequired: true,
      defaultValue: FromPage,
    },
    {
      id: 'FirstName',
      name: 'Имя',
      isRequired: true,
      defaultValue: isDebug ? 'test' : '',
    },
    {
      id: 'LastName',
      name: 'Фамилия',
      // isRequired: true,
      defaultValue: isDebug ? 'test' : '',
    },
    {
      id: 'PhoneNumber',
      name: 'Номер телефона',
      isRequired: true,
      defaultValue: isDebug ? 'test' : '',
    },
    {
      id: 'EMail',
      name: 'E-mail',
      // isRequired: true,
      defaultValue: isDebug ? 'test' : '',
    },
    /* // UNUSED: ApartmentType
     * {
     *   id: 'ApartmentType',
     *   name: 'Параметры квартиры',
     *   // useLabel: false,
     *   // isRequired: true,
     *   // defaultValue: isDebug ? 'test' : '',
     *   inputType: 'Select',
     *   inputProps: {
     *     isMulti: true,
     *     closeMenuOnSelect: false,
     *     // chakra-react-select
     *     options: [
     *       {
     *         // id: 'RoomsAmount',
     *         label: 'Количество комнат',
     *         isMulti: false,
     *         options: [
     *           { value: 'Студия', label: 'Студия' },
     *           { value: '1-комнатная', label: '1-комнатная' },
     *           { value: '2-комнатная', label: '2-комнатная' },
     *           { value: '3-комнатная', label: '3-комнатная' },
     *           { value: 'Больше комнат', label: 'Больше комнат' },
     *         ],
     *       },
     *       {
     *         // id: 'Location',
     *         label: 'Расположение',
     *         options: [
     *           { value: 'Рядом с лесом или парком', label: 'Рядом с лесом или парком' },
     *           { value: 'Рядом с метро', label: 'Рядом с метро' },
     *           { value: 'В Новой Москве', label: 'В Новой Москве' },
     *           { value: 'В ЦАО', label: 'В ЦАО' },
     *         ],
     *       },
     *     ],
     *     [> // children (Regular `Select)
     *      * children: (
     *      *   <>
     *      *     <option value="">--</option>
     *      *     <option value="Студия">Студия</option>
     *      *     <option value="1-комнатная">1-комнатная</option>
     *      *     <option value="2-комнатная">2-комнатная</option>
     *      *     <option value="3-комнатная">3-комнатная</option>
     *      *     <option value="Больше комнат">Больше комнат</option>
     *      *   </>
     *      * ),
     *      <]
     *   },
     * },
     */
  ]
}

export interface TRequestDetailsFormParams {
  FromPage: string
  isDark?: boolean
  onSuccess?: () => void
}
function RequestDetailsForm(props: TRequestDetailsFormParams) {
  const { FromPage, onSuccess, isDark } = props
  const isDebug = config.build.isDevServer
  // prettier-ignore
  const fields = React.useMemo(() => getFileds({ FromPage, isDebug}), [FromPage, isDebug])
  const introText = '' // 'Вводный текст формы'
  // console.log('@:RequestDetailsForm', {
  //   FromPage,
  //   collectionId,
  //   fields,
  //   successText,
  //   introText,
  // })
  const formProps = {
    collectionId,
    fields,
    successText,
    introText,
    onSuccess,
    onDark: isDark,
  }
  return <BasicForm {...formProps} />
}

export default RequestDetailsForm
