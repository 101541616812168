/** @module OrderConsultationFormModal
 *  @since 2022.02.11, 23:30
 *  @changed 2022.02.11, 23:30
 */

import React from 'react'
import classnames from 'classnames'
import { TReactContent } from '@utils/react-types'
import BasicModal from '@ui-elements/BasicModal'
import OrderConsultationForm, { TOrderConsultationFormParams, title } from './OrderConsultationForm'

// import styles from './OrderConsultationFormModal.module.less'

interface TOrderConsultationFormModalProps extends TOrderConsultationFormParams {
  className?: string
  isOpen?: boolean
  onClose?: () => void
  agreeAction?: () => void // ???
  children?: TReactContent
}

function OrderConsultationFormModal(props: TOrderConsultationFormModalProps) {
  const {
    isOpen,
    onClose,
    // agreeAction,
    className,
    // Form props...
    FromPage,
    // {...restProps}
  } = props
  const rootClassName = classnames(className /* , styles.root */)
  const modalProps = {
    id: 'OrderConsultationFormModal',
    isOpen,
    onClose,
    contentClassName: rootClassName,
    // defaultText: 'Согласен',
    title,
    // size: 'lg', // ???
    actionButtons: false,
    // scrollableBody: false,
    // contentClassName,
    // bodyClassName,
  }
  return (
    <BasicModal {...modalProps}>
      <OrderConsultationForm onSuccess={onClose} FromPage={FromPage} />
    </BasicModal>
  )
}

export default OrderConsultationFormModal
