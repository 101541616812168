/** @module PageText
 *  @since 2022.01.29, 18:49
 *  @changed 2022.01.29, 18:57
 */

import * as React from 'react'
import { Text, TextProps } from '@chakra-ui/react'
import classnames from 'classnames'
import { TReactContent } from '@utils/react-types'

import styles from './PageText.module.less'

export interface TPageTextProps extends TextProps {
  className?: string
  onDark?: boolean
  children: TReactContent
}

export default function PageText(props: TPageTextProps) {
  const { className, onDark, children, ...extraProps } = props
  const rootClassName = classnames(className, styles.root, onDark && styles.OnDark)
  return (
    <Text {...extraProps} className={rootClassName}>
      {children}
    </Text>
  )
}
