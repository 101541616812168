/** @module GenericInput
 *  @since 2022.01.27, 19:21
 *  @changed 2022.02.02, 23:41
 */

import React from 'react'
import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  InputProps,
  // Select,
  // SelectProps,
} from '@chakra-ui/react'
import { Select } from 'chakra-react-select'
import { TReactContent } from '@utils/react-types'

// import styles from './GenericInput.module.less'
// const styles: Record<string, any> = {} // Stub for module styles

type TInputTypes = 'Input' | 'Select'

interface TInputExtraProps {
  id?: string
  isError?: boolean
  defaultText: TReactContent
  errorText?: TReactContent
}
function InputExtra({ /* id, */ isError, defaultText, errorText }: TInputExtraProps) {
  if (isError) {
    return <FormErrorMessage>{errorText || defaultText || 'Error'}</FormErrorMessage>
  }
  if (defaultText) {
    return <FormHelperText>{defaultText}</FormHelperText>
  }
  return null
}

interface TGenericInputProps<T> {
  value: T
  id: string
  name: string
  placeholder?: string
  defaultText?: string
  errorText?: string
  isRequired?: boolean
  isDisabled?: boolean
  setValue?: (value: T) => void
  setError?: (id: string, errorType?: string) => void
  onValueChanged?: (id: string, value?: T) => void
  isChanged?: boolean
  useLabel?: boolean
  inputType?: TInputTypes
  inputProps?: InputProps & typeof Select // & SelectProps
  // options?: Pick<SelectProps, children>
}
const GenericInput = React.memo(
  // const GenericInput =
  (props: TGenericInputProps<string>) => {
    const {
      id,
      name,
      placeholder,
      value,
      setValue,
      onValueChanged,
      setError,
      isChanged,
      defaultText,
      errorText,
      isRequired,
      isDisabled,
      useLabel = true,
      inputType = 'Input',
      // options,
      inputProps, // Specific input component props...
    } = props
    function getError(value) {
      const isError = /* isChanged && */ isRequired && !value
      const errorType = isError ? 'EMPTY' : undefined
      return errorType
    }
    // const onChange = React.useCallback((ev) => {
    const onChange = (evOrParamsOrValue) => {
      // Event...
      if (evOrParamsOrValue.target) {
        evOrParamsOrValue = evOrParamsOrValue.target
      }
      // Object with value property...
      if (evOrParamsOrValue.value != null) {
        evOrParamsOrValue = evOrParamsOrValue.value
      }
      // List...
      if (Array.isArray(evOrParamsOrValue)) {
        evOrParamsOrValue = evOrParamsOrValue.map(({ value }) => value)
      }
      onValueChanged && onValueChanged(id, evOrParamsOrValue)
      setValue && setValue(evOrParamsOrValue)
      const errorType = getError(evOrParamsOrValue)
      setError && setError(id, errorType)
    }
    // }, [id])
    const errorType = getError(value)
    const isError = !!errorType && (errorType !== 'EMPTY' || isChanged)
    // const InputComponent = inputTypes[inputType]
    const inputComponentProps = {
      // type: 'email',
      id,
      value,
      placeholder: placeholder || name,
      onChange,
      isDisabled,
      tabIndex: 1,
      // isRequired,
      ...inputProps,
    }
    let inputContent
    if (inputType === 'Select') {
      inputContent = <Select {...(inputComponentProps as unknown as typeof Select)} value={undefined} />
    } else {
      inputContent = <Input {...inputComponentProps} />
    }
    // console.log('@:OrderConsultationFormSection:GenericInput', {
    //   id,
    //   inputType,
    //   // inputTypes,
    //   // InputComponent,
    //   value,
    //   isChanged,
    //   isRequired,
    //   isError,
    //   // inputProps,
    // })
    // if (inputType === 'Select' || id === 'ApartmentType') {
    //   debugger
    // }
    return (
      <FormControl isRequired={isRequired} isInvalid={isError} my={4}>
        {useLabel && <FormLabel>{name}</FormLabel>}
        {inputContent}
        <InputExtra
          id={id}
          isError={isError}
          defaultText={defaultText || (isRequired && 'Поле обязательно для заполнения')}
          errorText={errorText}
        />
      </FormControl>
    )
  }
)

export default GenericInput
