/** @module OrderCallbackFormModal
 *  @since 2022.02.02, 22:05
 *  @changed 2022.02.02, 22:06
 */

import React from 'react'
import classnames from 'classnames'
import { TReactContent } from '@utils/react-types'
import BasicModal from '@ui-elements/BasicModal'
import OrderCallbackForm, { title } from './OrderCallbackForm'

// import styles from './OrderCallbackFormModal.module.less'

interface TOrderCallbackFormModalProps {
  className?: string
  isOpen?: boolean
  onClose?: () => void
  // agreeAction?: () => void // ???
  children?: TReactContent
}

function OrderCallbackFormModal(props: TOrderCallbackFormModalProps) {
  const {
    isOpen,
    onClose,
    // agreeAction,
    className,
  } = props
  const rootClassName = classnames(className /* , styles.root */)
  const modalProps = {
    id: 'OrderCallbackFormModal',
    isOpen,
    onClose,
    // size: 'xl', // xs, sm, md, lg, xl, or full
    // defaultAction: handleDefaultAction,
    contentClassName: rootClassName,
    // defaultText: 'Согласен',
    title,
    // size: 'lg', // ???
    actionButtons: false,
    // scrollableBody: false,
    // contentClassName,
    // bodyClassName,
  }
  // function onSuccess() {
  //   if (onClose) {
  //     onClose()
  //   }
  // }
  return (
    <BasicModal {...modalProps}>
      <OrderCallbackForm onSuccess={onClose} />
    </BasicModal>
  )
}

export default OrderCallbackFormModal
