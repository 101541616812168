/** @module NavBar
 *  @desc Top page header with logo, menu, hot actions etc.
 *  @since 2022.01.04, 20:38
 *  @changed 2022.03.06, 11:12
 */

import React, { useCallback } from 'react'
import { Box, Flex } from '@chakra-ui/react'
import Logo from '@components/Logo'
import classnames from 'classnames'
import NavSideOverlay from '@components/NavSideOverlay'
import Wrapper from '@components/Wrapper'
import useStore from '@global/store'
// import MainActions from '@blocks/MainActions'
import PageLink from '@ui-support/PageLink'
import TopSplash from '@components/TopSplash/TopSplash'
import { useScroll } from '@ui-support/useScroll'
import HamburgerBox from '@ui-elements/HamburgerBox'
import PhoneBox from '@ui-elements/PhoneBox'
import config from '@config'

import styles from './NavBar.module.less'

// TODO: Store in config
// const logoWidth = '70px'
const logoHeight = '70px'

const onDark = true
const scrollTresholdY = 30

/* // NOTE: Temporarily unused.
 * function LogoSection() {
 *   // const router = useRouter()
 *   // const onLogoClick = () => {
 *   //   router.push('/')
 *   // }
 *   return (
 *     <Box
 *       className={styles.LogoSection}
 *       // width={logoWidth}
 *       height={logoHeight}
 *       p={2}
 *       alignSelf="flex-start"
 *       display={['none', 'none', 'block', 'block']}
 *     >
 *       <PageLink href="/" isFlex>
 *         <Logo onDark={onDark} isHorizontal isFlex />
 *       </PageLink>
 *     </Box>
 *   )
 * }
 * function ActionsSection() {
 *   return (
 *     <Stack
 *       className={styles.MainActionsBlock}
 *       // NOTE: Sync breakpoints with `NavSideOverlay`
 *       display={['none', 'none', 'none', 'none', 'block']}
 *       // spacing={[0, 0, 0, 0, '20px', '40px']}
 *       direction="row"
 *     >
 *       <MainActions className={styles.MainActionsContainer} onDark />
 *     </Stack>
 *   )
 * }
 */

interface TNavBarProps {
  className?: string
  showTopSplash?: boolean
}

/* // UNUSED: TempTextsBlock -- Can be placed instead unused buttons
 * function TempTextsBlock() {
 *   // Temporarily texts...
 *   return (
 *     <Flex
 *       className={styles.TempTextsBlock}
 *       justify="center"
 *       // marginTop="20px"
 *       // direction="column"
 *     >
 *       <Text className={styles.TempTextsBlockItem}>
 *         Все услуги компании по покупке квартир в новостройках для вас бесплатные.
 *       </Text>
 *     </Flex>
 *   )
 * }
 */

function NavBar(props: TNavBarProps) {
  const { className, showTopSplash = true } = props
  const { useHideableNavBar } = config.app
  const { scrollY } = useScroll()
  const isScrolled = scrollY >= scrollTresholdY
  const showNavBar = !showTopSplash || !useHideableNavBar || isScrolled
  // const topEl = process.browser && document.getElementById('TopSplash')
  const setNavOverlayOpen = useStore((state) => state.setNavOverlayOpen)
  // console.log('@:NavBar', {
  //   scrollY,
  // })
  const handleNavLinkClick = useCallback(() => {
    setNavOverlayOpen(true)
  }, [])
  // prettier-ignore
  const rootClassName = classnames([
    className,
    styles.root,
    onDark && styles.OnDark,
    showNavBar && styles.ShowNavBar,
    isScrolled && styles.Scrolled,
    styles.Fixed,
    styles.Hideable,
  ])
  // prettier-ignore
  return (
    <>
      <Box
        // as="header"
        // position="fixed"
        w="100%"
        className={rootClassName}
      >
        <NavSideOverlay className={styles.NavSideOverlay} />
        <div className={styles.Wrapper}>
          <HamburgerBox className={styles.HamburgerBox} handleNavLinkClick={handleNavLinkClick} onDark={onDark} />
          <Wrapper as="header" className={styles.Header}>
            <Box className={styles.LayoutBox} position="relative" zIndex={1}>
              <Flex
                className={styles.LayoutFlex}
                align="center"
                // justify="space-between"
                justify={['flex-end', null, 'space-between']}
                p={2}
              >
                <Box
                  className={styles.LogoBox}
                  display={['none', 'none', 'block', 'block']}
                  // width={logoWidth}
                  height={logoHeight}
                  p={2}
                  // alignSelf={['flex-end']}
                  // "flex-start"
                  // display={['none', 'none', 'block', 'block']}
                >
                  <PageLink href="/" isFlex>
                    <Logo onDark={onDark} isHorizontal isFlex />
                  </PageLink>
                </Box>
                <PhoneBox
                  className={styles.PhoneBox}
                  // display={['none', 'none', 'block', 'block']}
                  onDark={onDark}
                />
              </Flex>
            </Box>
          </Wrapper>
        </div>
      </Box>
      {showTopSplash && (
        <TopSplash key="TopSplash" onDark={onDark} showNavBar={showNavBar} handleNavLinkClick={handleNavLinkClick} />
      )}
    </>
  )
}

export default NavBar
