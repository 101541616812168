/** @module BasicModal
 *  @since 2022.02.02, 21:16
 *  @changed 2022.02.02, 21:16
 */

import React from 'react'
import classnames from 'classnames'
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps,
} from '@chakra-ui/react'
import { TReactContent } from '@utils/react-types'

import styles from './BasicModal.module.less'

interface TBasicModalProps {
  id?: string
  className?: string
  isOpen?: boolean
  onClose?: () => void
  defaultAction?: () => void // ???
  children?: TReactContent
  defaultText?: string
  closeText?: string
  title: string
  contentClassName?: string
  bodyClassName?: string
  actionButtons?: boolean | TReactContent
  scrollableBody?: boolean
  onSuccess?: () => void
}

function BasicModal(props: TBasicModalProps) {
  const {
    // id,
    isOpen,
    onClose,
    defaultAction,
    // className,
    defaultText = 'Ok',
    closeText = 'Закрыть',
    title,
    children,
    contentClassName,
    bodyClassName,
    actionButtons,
    scrollableBody = true,
    // onSuccess,
    ...extraProps
  } = props
  const handleDefaultAction = () => {
    onClose()
    if (defaultAction) {
      defaultAction()
    }
  }
  const buttonsContent =
    actionButtons === false ? null : actionButtons && actionButtons !== true ? (
      actionButtons
    ) : (
      <>
        <Button colorScheme="primary" mr={3} onClick={handleDefaultAction}>
          {defaultText}
        </Button>
        <Button variant="ghost" onClick={onClose}>
          {closeText}
        </Button>
      </>
    )
  // console.log('@:BasicModal', {
  //   id,
  //   extraProps,
  // })
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      // autoFocus={false} // Using auto-focus. See `tabIndex` chakra input props.
      preserveScrollBarGap
      {...(extraProps as ModalProps)}
    >
      <ModalOverlay />
      <ModalContent className={classnames(styles.Content, contentClassName)}>
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody className={classnames(styles.Body, scrollableBody && styles.BodyScrollable, bodyClassName)}>
          {children}
        </ModalBody>
        {buttonsContent && <ModalFooter>{buttonsContent}</ModalFooter>}
      </ModalContent>
    </Modal>
  )
}

export default BasicModal
