/** @module PageSection
 *  @desc Main page sections wrapper (provides referable id (as page anchor) and top margin for correct display under sticky page header).
 *  @since 2022.01.04, 20:38
 *  @changed 2022.01.04, 20:38
 */

import React from 'react'
import classnames from 'classnames'

import { Box, StyleProps } from '@chakra-ui/react'
import { TReactContent } from '@utils/react-types'

import styles from './PageSection.module.scss'

interface TPageSectionProps extends StyleProps {
  className?: string
  id?: string
  children?: TReactContent
  noTopSpace?: boolean
  isDark?: boolean
}

function PageSection(props: TPageSectionProps) {
  const {
    // prettier-ignore
    id,
    className,
    children,
    noTopSpace,
    isDark,
    ...restProps
  } = props
  const rootClassName = classnames([
    // prettier-ignore
    className,
    styles.root,
    noTopSpace || styles.withTopSpace,
    isDark && styles.isDark,
  ])
  return (
    <Box
      as="section"
      id={id}
      className={rootClassName}
      // bgColor="primary.500"
      {...restProps}
    >
      {children}
    </Box>
  )
}

export default PageSection
