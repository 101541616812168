import React from 'react'
import { ReactElement } from 'react'
import { As, Box, StyleProps } from '@chakra-ui/react'
import classnames from 'classnames'
import { TReactContent } from '@utils/react-types'
// import { styles } from '@config/links'

const paddingX = ['20px', '30px', '0.75in', '1.25in']

const widthBySizes = {
  sm: 600,
  md: 800,
  xl: 1000,
  '2xl': 1200,
  '3xl': 1400,
  '4xl': 1640,
}
const defaultSize = '4xl'

// React.PropsWithChildren<React.ReactNode>
interface TWrapperProps extends StyleProps {
  className?: string
  // padded?: boolean
  children?: TReactContent
  as?: As<any>
  size?: keyof typeof widthBySizes
}

function getMaxWidthBySize(size = defaultSize) {
  let width = widthBySizes[size]
  if (typeof width === 'number') {
    width = String(width) + 'px'
  }
  return width
}

function Wrapper(props: TWrapperProps): ReactElement {
  const {
    // padded = true,
    as,
    className,
    size,
    ...restProps
  } = props
  const maxWidth = getMaxWidthBySize(size)
  return (
    <Box
      as={as}
      className={classnames(className, 'Wrapper')}
      // paddingX={padded && constantStyles.paddingX}
      // paddingX={['20px', '30px'paddingX]}
      paddingX={paddingX}
      maxWidth={maxWidth}
      margin="0 auto"
      {...restProps}
    >
      {props.children}
    </Box>
  )
}

export default Wrapper
