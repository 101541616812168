/** @module Logo
 *  @since 2022.01.04, 20:38
 *  @changed 2022.01.04, 20:38
 */

import React from 'react'

import classnames from 'classnames'

import logoOnWhite from '@global/assets/logo/gerb-logo.svg'
import logoOnDark from '@global/assets/logo/gerb-logo-on-dark.svg'
import logoOnWhiteHor from '@global/assets/logo/gerb-logo-hor.svg'
import logoOnDarkHor from '@global/assets/logo/gerb-logo-on-dark-hor.svg'

import styles from './Logo.module.less'

interface TLogoProps {
  className?: string
  onDark?: boolean
  isHorizontal?: boolean
  isFlex?: boolean
}

function getLogoUrl({ isHorizontal, onDark }: TLogoProps) {
  if (onDark) {
    return isHorizontal ? logoOnDarkHor : logoOnDark
  } else {
    return isHorizontal ? logoOnWhiteHor : logoOnWhite
  }
}

function Logo(props: TLogoProps) {
  const { className, isFlex } = props
  const logo = getLogoUrl(props)
  return (
    <div className={classnames(className, styles.root, isFlex && styles.Flex)}>
      <img className={classnames(styles.LogoImg)} src={logo} />
    </div>
  )
}

export default Logo
