/** @module BasicFormSection
 *  @since 2022.01.28, 21:57
 *  @changed 2022.03.13, 23:17
 */

import React from 'react'
import classnames from 'classnames'
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormErrorMessage,
  Link,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
// import * as firebase from 'firebase/app'
// import useStore from '@global/store'
import config from '@config'
import { faCheck, faAngleRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import GenericInput from '@forms-support/GenericInput'
import { TReactContent } from '@utils/react-types'
import AgreementModal from '@components/Agreement/Modal/AgreementModal'
import Requestor from '@helpers/Requestor'

import styles from './BasicForm.module.scss'
// const styles: Record<string, any> = {} // Stub for module styles

enum EToastStatus {
  INFO = 'info',
  SUCCESS = 'success',
  ERROR = 'error',
  WARNING = 'warning',
}

/** Add error helper */
type TError = string // | Error
function addErrorToList(list: TError[], err?: TError) {
  if (!err) {
    return list
  }
  if (Array.isArray(err)) {
    return err.reduce((list, err) => {
      return addErrorToList(list, err)
    }, list)
  } else if (!list.includes(err)) {
    return [...list, err]
  }
  return list
}

type TFormValue = undefined | string | number
interface TFieldData {
  id: string
  name: string
  isRequired?: boolean
  isHidden?: boolean
  defaultValue?: TFormValue
}
interface TBasicForm {
  fields: TFieldData[]
  collectionId: string
  successTitle?: string
  successText?: string // TReactContent
  introText?: TReactContent
  onSuccess?: () => void
  onDark?: boolean
}

function BasicForm(props: TBasicForm) {
  const requestor = new Requestor({ id: 'BasicForm' })
  const {
    fields,
    collectionId,
    successTitle = 'Готово!',
    successText = 'Данные отправлены',
    introText,
    onSuccess,
    onDark,
  } = props
  // const {
  //   firestore,
  //   // appInited,
  //   ipAddr,
  //   Token,
  // } = useStore(({ firestore, appInited, ipAddr, Token }) => ({
  //   firestore,
  //   appInited,
  //   ipAddr,
  //   Token,
  // }))
  // TODO: Display different component state untill not `appInited`?
  /* console.log('@:BasicFormSection:start', {
   *   onSuccess,
   *   // props,
   *   fields,
   *   // firestore,
   *   // appInited,
   *   // ipAddr,
   *   // Token,
   * })
   */
  const isDebug = config.build.isDevServer
  // const [isDisabled, setIsDisabled] = React.useState(false)
  const [isLoading, setIsLoading] = React.useState(false)
  const [isSubmitted, setIsSubmitted] = React.useState(false) // ???
  const toast = useToast()
  function showToast(status: EToastStatus, title: string, description: string) {
    toast({
      position: 'top',
      title,
      description,
      status,
      duration: 5000,
      isClosable: true,
    })
  }
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [changedKeys, setChangedKeys] = React.useState([])
  // const [values, setValues] = React.useState({})
  const [values, setValues] = React.useState(
    fields.reduce((data, { id, defaultValue }) => {
      // console.log('@:BasicForm: init values', { id, defaultValue })
      return { ...data, [id]: defaultValue }
    }, {})
  )
  // const showDebugErrors = false && isDebug
  const defaultErrors = /* showDebugErrors ? { firstName: 'EMPTY', Save: ['Нет данных', 'Ошибка сети'] } :*/ {}
  const [errors, updateErrors] = React.useState(defaultErrors)
  const [agreed, setAgreed] = React.useState(isDebug ? true : false)
  const setAgreedToTrue = () => {
    setIsSubmitted(false)
    setAgreed(true)
  }
  function openAgreementModal(ev) {
    ev.preventDefault()
    onOpen()
    return false
  }
  const onValueChanged = React.useCallback(
    (id, value?: any) => {
      const updatedChangedKeys = changedKeys.includes(id) ? changedKeys : [...changedKeys, id]
      const updatedValues = values[id] === value ? values : { ...values, [id]: value }
      // console.log('@:BasicFormSection:BasicForm:onValueChanged', {
      //   id,
      //   value,
      //   updatedValues,
      //   updatedChangedKeys,
      //   changedKeys,
      // })
      if (updatedValues !== values) {
        setValues(updatedValues)
      }
      if (updatedChangedKeys !== changedKeys) {
        setChangedKeys(updatedChangedKeys)
      }
      setIsSubmitted(false)
    },
    [changedKeys, values]
  )
  const setError = React.useCallback(
    (id, errorData = undefined) => {
      if (errors[id] !== errorData) {
        if (errorData) {
          /* console.log('@:BasicFormSection:BasicForm:setError', {
           *   id,
           *   errorData,
           * })
           */
        }
        updateErrors({ ...errors, [id]: errorData })
      }
    },
    [errors]
  )
  const actualErrorIds = Object.keys(errors).filter((id) => !!errors[id])
  const localErrorIds = actualErrorIds.filter((id) => id !== 'SendData')
  const displayErrorIds = actualErrorIds // .filter((id) => errors[id] !== 'EMPTY'/*  || changedKeys[id] */)
  const errorMessages = displayErrorIds.reduce((errorsList, id) => {
    const err = errors[id]
    return addErrorToList(errorsList, err)
  }, [])
  // const hasActualErrors = !!actualErrorIds.length
  const hasLocalErrors = !!localErrorIds.length
  const hasDisplayErrors = !!displayErrorIds.length
  const hasRequredValues =
    agreed &&
    fields.reduce((result, { isRequired, id }) => {
      return result && (!isRequired || !!values[id])
    }, true)
  // !!(firstName && lastName && phoneNumber && agreed)
  const isDisabled = isLoading
  const allowedToSend = !hasLocalErrors && hasRequredValues
  function submitToDatabase(/* id, */ formData) {
    // @see https://www.npmjs.com/package/firebase
    // @see https://console.firebase.google.com/project/march-team/firestore/data/
    // const collection = firestore.collection(collectionId)
    // const { FieldValue } = firebase.firestore
    // const Created = FieldValue.serverTimestamp()
    const filteredData = Object.entries(formData).reduce((result, [id, val]) => {
      return id && val != null && val !== '' ? { ...result, [id]: val } : result
    }, {})
    const setData = {
      ...filteredData,
      requestType: collectionId,
      // Created,
      // SessionId: Token,
      // IpAddress: ipAddr,
    }
    const url = config.api.apiUrlPrefix + '/requests/add'
    const method = 'POST'
    // eslint-disable-next-line no-console
    console.log('@:BasicFormSection:BasicForm:submitToDatabase: request start', {
      url,
      method,
      collectionId,
      // id,
      hasRequredValues,
      formData,
      // firestore,
      // collection,
      setData,
      // Token,
      // ipAddr,
      // appInited,
    })
    // return collection.doc(id).set(setData) // OLD_CODE: Firebase
    return (
      requestor
        .fetch({ url, method, data: setData })
        // .then((res) => res.json())
        .then((result) => {
          // eslint-disable-next-line no-console
          console.log('@:BasicFormSection:BasicForm:submitToDatabase: request done', {
            result,
          })
          // debugger
          /* // Handle error?
           * if (!Token || !ipAddr) {
           *   const error = new Error('Got invalid initalization parameters.')
           *   // eslint-disable-next-line no-console
           *   console.error('@:BasicFormSection:BasicForm:submitToDatabase: request error', String(error), {
           *     error,
           *     Token,
           *     ipAddr,
           *     data,
           *     // Params...
           *     url,
           *     location,
           *     // projectId,
           *     // appId,
           *   })
           *   // eslint-disable-next-line no-debugger
           *   debugger
           * }
           */
          // set({ appInited: true, Token, ipAddr })
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.error('@:BasicFormSection:BasicForm:submitToDatabase: request catch', {
            error,
            // Source params...
            url,
            // fetchOptions,
            // location,
            // projectId,
            // appId,
            // firebase,
          })
          // eslint-disable-next-line no-debugger
          debugger
          throw error
          // Show toast...
          // showToast({
          //   title: 'Неудачная попытка связи с сервером',
          //   description: String(error),
          // })
        })
    )
  }
  async function handleSend() {
    if (allowedToSend) {
      setIsLoading(true)
      // setIsDisabled(true)
      const formData = fields.reduce((data, { id }) => ({ ...data, [id]: values[id] }), {})
      // const id = Object.values(formData).filter(Boolean).join(' ')
      try {
        // console.log('@:BasicFormSection:BasicForm:handleSend: start', {
        //   id,
        //   formData,
        // })
        // debugger
        await submitToDatabase(/* id, */ formData)
        // console.log('@:BasicFormSection:BasicForm:handleSend: done', {
        //   id,
        //   formData,
        // })
        // debugger
        showToast(EToastStatus.SUCCESS, successTitle, successText)
        if (typeof onSuccess === 'function') {
          setIsSubmitted(true)
          setChangedKeys([])
          onSuccess(/*{ id, formData }*/)
        }
      } catch (error) {
        const traceError = error
        // eslint-disable-next-line no-console
        const errorText = [
          'Ошибка сохранения данных',
          error && String(error), // error.message,
        ]
          .filter(Boolean)
          .join(': ')
        // eslint-disable-next-line no-console
        console.error('@:BasicFormSection:BasicForm:handleSend: error', error, {
          // id,
          formData,
          errorText,
          // error,
          traceError,
        })
        // eslint-disable-next-line no-debugger
        debugger
        setError('SendData', errorText)
        // setHasBadSubmission(true)
        // setIsDisabled(false)
        showToast(EToastStatus.ERROR, 'Ошибка!', errorText)
      }
    }
    setIsLoading(false)
  }
  const genericInputProps = {
    onValueChanged,
    setError,
  }
  const fieldsContent = fields
    .map((inputProps: TFieldData) => {
      const { id, isHidden /* , name, isRequired, defaultValue */ } = inputProps
      if (isHidden) {
        return null
      }
      const fieldProps = {
        key: id,
        ...genericInputProps,
        ...inputProps,
        // id,
        // name,
        // isRequired,
        value: values[id] || '',
        // setValue: setFirstName,
        isChanged: changedKeys.includes(id),
        isDisabled,
      }
      // console.log('@:BasicForm:fieldsContent: item', fieldProps)
      return <GenericInput {...fieldProps} />
    })
    .filter(Boolean)
  // console.log('@:BasicFormSection:BasicForm')
  const iconSource = isSubmitted ? faCheck : faAngleRight
  const iconElem = <FontAwesomeIcon icon={iconSource} />
  const rootClassName = classnames(styles.root, onDark && styles.onDark)
  const isActionDisabled = !allowedToSend || isDisabled || isSubmitted
  return (
    <FormControl as="form" className={rootClassName} isInvalid={hasDisplayErrors} isDisabled>
      {!!introText && (
        <Text fontSize="md" my="20px" alignSelf="flex-start" textAlign="left">
          {introText}
        </Text>
      )}
      {hasDisplayErrors && (
        <Box className={styles.Errors}>
          {errorMessages.map((err) => {
            if (err === 'EMPTY') {
              err = 'Требуется заполнить обязательные поля.'
            }
            return <FormErrorMessage key={err}>{err}</FormErrorMessage>
          })}
        </Box>
      )}
      {fieldsContent}
      <FormControl
        className={styles.AgreementSection}
        isRequired
        // isInvalid={isError}
        my={4}
      >
        <Checkbox
          isChecked={agreed}
          onChange={(e) => setAgreed(e.target.checked)}
          isDisabled={isDisabled}
          tabIndex={1}
        >
          Заполняя форму, я даю согласие на обработку персональных данных.{' '}
          {/*
          <NextLink href="/agreement"><a>Соглашение</a></NextLink>
          */}
          <Link onClick={openAgreementModal}>Соглашение</Link>.
        </Checkbox>
      </FormControl>
      <FormControl isRequired my={4}>
        <Button
          isDisabled={isActionDisabled}
          variant={isActionDisabled ? 'outlined' : 'solid'}
          isLoading={isLoading}
          colorScheme={onDark ? 'secondary' : 'primary'}
          onClick={handleSend}
          leftIcon={iconElem}
          tabIndex={1}
        >
          Отправить
        </Button>
      </FormControl>
      {/* Agreement modal */}
      <AgreementModal isOpen={isOpen} onClose={onClose} agreeAction={setAgreedToTrue} />
    </FormControl>
  )
}

export default BasicForm
