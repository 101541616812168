/** @module NavSideOverlay
 *  @desc NavBar overlay menu.
 *  @since 2022.01.04, 20:38
 *  @changed 2022.02.23, 00:57
 */

import { ReactElement, useCallback } from 'react'
import useStore, { TState } from '@global/store'
import { Text, Box, Flex } from '@chakra-ui/react'
import HamburgerMenu from 'react-hamburger-menu'
import classnames from 'classnames'
import Logo from '@components/Logo'
import Locations from '@blocks/Locations'
import Socials from '@blocks/Socials'
import Contacts from '@blocks/Contacts'
// import MainActions from '@blocks/MainActions'
import PageLink from '@ui-support/PageLink'

import styles from './NavSideOverlay.module.less'

interface TNavSideOverlayProps {
  className?: string
}

// TODO: Store in config
// const logoWidth = '70px'
const logoHeight = '160px'

// const fakeMenuClicked = () => null

function LogoBlock({ handleNavLinkClick }) {
  return (
    <Flex
      className={styles.LogoBlock}
      justify="center"
      // width={logoWidth}
      height={logoHeight}
      p={2}
      marginBottom={8}
      // marginTop="-1px"
      // marginLeft="-16px"
    >
      <PageLink onClick={handleNavLinkClick} href="/" isFlex>
        <Logo onDark isFlex />
      </PageLink>
    </Flex>
  )
}
/* // UNUSED: ExtraBox
 * function ExtraBox() {
 *   const {
 *     phoneNumber,
 *   } = siteConfig
 *   return (
 *     <Box
 *       className={styles.ExtraBox}
 *       // NOTE: Sync breakpoints with `NavSideOverlay`
 *       display={['none', 'none', 'block', 'block']}
 *       marginLeft="3px"
 *     >
 *       <Flex className={styles.ActionsFlex} align="center" justify="flex-end" p={2}>
 *         <Box className={styles.ActionsFeedback} p={4}>
 *           <Button colorScheme="primary">Оставить заявку</Button>
 *         </Box>
 *         <Box className={styles.ActionsPhone} p={4}>
 *           <Text
 *             whiteSpace="nowrap"
 *             fontSize="xl"
 *           >
 *             {phoneNumber}
 *           </Text>
 *         </Box>
 *       </Flex>
 *     </Box>
 *   )
 * }
 */
function FloatHamburgerBlock({ handleNavLinkClick }) {
  return (
    <Flex className={styles.CloseIconSection}>
      <HamburgerMenu
        className={styles.CloseIcon}
        isOpen={true}
        // menuClicked={() => undefined}
        cursor="pointer"
        menuClicked={handleNavLinkClick}
        height={16}
        width={16}
        strokeWidth={2}
        rotate={180}
      />
    </Flex>
  )
}
// UNUSED...
function LocationsBlock({ handleNavLinkClick }) {
  return (
    <Box
      className={styles.Locations}
      // marginBottom="20px"
    >
      <Locations
        className={styles.LocationsBlock}
        itemClassName={styles.LocationsItem}
        handleNavLinkClick={handleNavLinkClick}
      />
      {/* locations.map(({ href, label }) => (
        <Fragment key={href}>
          <LocationsItem
            className={styles.LocationsItem}
            href={href}
            label={label}
            handleNavLinkClick={handleNavLinkClick}
          />
        </Fragment>
      )) */}
    </Box>
  )
}
/* // function MainActionsBlock({ handleNavLinkClick }) {
 *   return (
 *     <Flex className={styles.MainActionsBlock} justify="center" marginTop="20px">
 *       <MainActions
 *         handleNavLinkClick={handleNavLinkClick}
 *         className={styles.MainActionsContainer}
 *         itemClassName={styles.MainActionsItem}
 *         onDark
 *       />
 *     </Flex>
 *   )
 * }
 */
function TempTextsBlock() {
  // Temporarily texts...
  return (
    <Flex className={styles.TempTextsBlock} justify="center" marginTop="20px" direction="column">
      <Text className={styles.TempTextsBlockItem}>
        Все услуги компании по покупке квартир в новостройках для вас бесплатные.
      </Text>
      <Text className={styles.TempTextsBlockItem}>Оформим ипотеку.</Text>
      <Text className={styles.TempTextsBlockItem}>
        Купить квартиру в ипотеку можно не только в Москве и МО, но и еще в 22 крупных городах России.
      </Text>
    </Flex>
  )
}
function ContactsBlock({ handleNavLinkClick }) {
  return (
    <Flex className={styles.ContactsBlock} justify="center" marginTop="50px">
      <Contacts
        handleNavLinkClick={handleNavLinkClick}
        className={styles.ContactsContainer}
        itemClassName={styles.ContactsItem}
        onDark
      />
    </Flex>
  )
}
function SocialsBlock({ handleNavLinkClick }) {
  return (
    <Flex className={styles.SocialsBlock} justify="center" marginTop="50px">
      <Socials
        handleNavLinkClick={handleNavLinkClick}
        className={styles.SocialsBar}
        itemClassName={styles.BottomSocialsItem}
        color="white"
      />
    </Flex>
  )
}

function NavSideOverlay(props: TNavSideOverlayProps): ReactElement {
  const { className } = props
  const isNavOverlayOpen = useStore((state: TState) => state.isNavOverlayOpen)
  const setNavOverlayOpen = useStore((state) => state.setNavOverlayOpen)
  const handleNavLinkClick = useCallback(() => {
    setNavOverlayOpen(false)
  }, [])
  return (
    <Box className={classnames(className, styles.root, isNavOverlayOpen && styles.IsOpen, styles.OnDark)}>
      <Flex
        className={styles.FlexRoot}
        direction="column"
        // paddingX="24px"
        width={['100%', 320]}
      >
        <FloatHamburgerBlock handleNavLinkClick={handleNavLinkClick} />
        <Flex className={styles.FlexContainer} direction="column" justify="center">
          <LogoBlock handleNavLinkClick={handleNavLinkClick} />
          {/* // NOTE 2022.02.18, 05:40 -- Temp.disabled production mode
           */}
          <LocationsBlock handleNavLinkClick={handleNavLinkClick} />
          {/*
          <MainActionsBlock handleNavLinkClick={handleNavLinkClick} />
          */}
          <TempTextsBlock />
          <ContactsBlock handleNavLinkClick={handleNavLinkClick} />
          <SocialsBlock handleNavLinkClick={handleNavLinkClick} />
        </Flex>
      </Flex>
      <Box className={styles.Curtain} onClick={handleNavLinkClick} width="100%" height="100%" />
    </Box>
  )
}

export default NavSideOverlay
