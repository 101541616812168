/** @module Locations
 *  @since 2022.01.16, 23:04
 *  @changed 2022.01.16, 23:04
 */

import React from 'react'
import { locations } from '@config/links'
// import { Heading, Text } from '@chakra-ui/react'
import classnames from 'classnames'

import styles from './Locations.module.less'
import LocationsItem from '@blocks/Locations/LocationsItem'

interface TLocationsProps {
  className?: string
  itemClassName?: string
  handleNavLinkClick?: () => void
}

function Locations(props: TLocationsProps) {
  const { className, itemClassName, handleNavLinkClick } = props
  return (
    <div className={classnames(className, styles.root)}>
      {locations.map(({ href, label } /* , index */) => (
        <LocationsItem
          key={href || label}
          className={classnames(itemClassName, styles.Item)}
          href={href}
          label={label}
          handleNavLinkClick={handleNavLinkClick}
        />
      ))}
    </div>
  )
}

export default Locations
