/** @module RequestDetailsFormModal
 *  @since 2022.02.11, 23:30
 *  @changed 2022.02.11, 23:30
 */

import React from 'react'
import classnames from 'classnames'
import { TReactContent } from '@utils/react-types'
import BasicModal from '@ui-elements/BasicModal'
import RequestDetailsForm, { TRequestDetailsFormParams, title } from './RequestDetailsForm'

// import styles from './RequestDetailsFormModal.module.less'

interface TRequestDetailsFormModalProps extends TRequestDetailsFormParams {
  className?: string
  isOpen?: boolean
  onClose?: () => void
  agreeAction?: () => void // ???
  children?: TReactContent
}

function RequestDetailsFormModal(props: TRequestDetailsFormModalProps) {
  const {
    isOpen,
    onClose,
    // agreeAction,
    className,
    // Form props...
    FromPage,
    // {...restProps}
  } = props
  // const handleDefaultAction = () => {
  //   onClose()
  //   if (agreeAction) {
  //     agreeAction()
  //   }
  // }
  const rootClassName = classnames(className /* , styles.root */)
  const modalProps = {
    id: 'RequestDetailsFormModal',
    isOpen,
    onClose,
    // defaultAction: handleDefaultAction,
    contentClassName: rootClassName,
    // defaultText: 'Согласен',
    title,
    // size: 'lg', // ???
    actionButtons: false,
    // scrollableBody: false,
    // contentClassName,
    // bodyClassName,
  }
  return (
    <BasicModal {...modalProps}>
      <RequestDetailsForm onSuccess={onClose} FromPage={FromPage} />
    </BasicModal>
  )
}

export default RequestDetailsFormModal
