/** @module Socials
 *  @since 2022.01.16, 23:50
 *  @changed 2022.01.16, 23:50
 */

import React from 'react'
import classnames from 'classnames'
import { Flex } from '@chakra-ui/react'
import { socials } from '@config/links'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { Link } from '@chakra-ui/react'
import PageLink from '@ui-support/PageLink'

import styles from './Socials.module.less'

// import SocialsItem from '@components/Socials/SocialsItem'

interface TSocialsProps {
  className?: string
  itemClassName?: string
  color?: string
  handleNavLinkClick?: () => void
}

function Socials(props: TSocialsProps) {
  const { className, itemClassName, color = 'black', handleNavLinkClick } = props
  return (
    <Flex whiteSpace="nowrap" className={classnames(className, styles.root)}>
      {socials.map(({ href, label, icon }) => (
        <PageLink
          className={classnames(itemClassName, styles.Item)}
          key={href}
          href={href}
          isExternal
          title={label}
          onClick={handleNavLinkClick}
        >
          <FontAwesomeIcon width="16px" color={color} icon={icon} />
        </PageLink>
      ))}
    </Flex>
  )
}

export default Socials
