/** @module FooterWithContacts
 *  @since 2022.01.04, 20:38
 *  @changed 2022.01.22, 21:36
 */

import React from 'react'
import { Box, Divider, Flex, Text } from '@chakra-ui/react'
// import AnchorLink from 'react-anchor-link-smooth-scroll'
import Logo from '@components/Logo'
import classnames from 'classnames'
import Socials from '@blocks/Socials'
import Contacts from '@blocks/Contacts'
import { TReactContent } from '@utils/react-types'
import Wrapper from '@components/Wrapper'
import PageLink from '@ui-support/PageLink'
import cssParams from '@global/cssParams'

import styles from './FooterWithContacts.module.scss'
import { GenericPageContext } from '@layout/GenericPageLayout/GenericPageContext'

interface TElement {
  className?: string
  children?: TReactContent
}

// TODO: Store in config
// const logoWidth = '50px'
const logoHeight = '50px'

function LogoBox({ isDark }) {
  return (
    <Box
      // marginTop="8px"
      className={styles.LogoBox}
      // width={logoWidth}
      height={logoHeight}
      flexShrink="0"
      m="4"
    >
      <PageLink href="/" isFlex>
        <Logo isHorizontal isFlex onDark={isDark} />
      </PageLink>
    </Box>
  )
}
/* // UNUSED: InfoBox
 * function InfoBox() {
 *   return (
 *     <Box m="4" flex="1" maxW="400px">
 *       <Text
 *         fontSize="sm"
 *         // textAlign="left"
 *         // lineHeight="22px"
 *       >
 *         Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's
 *         standard dummy text ever since the 1500s.
 *       </Text>
 *     </Box>
 *   )
 * }
 */

function SocialsBlock({ isDark }) {
  const color = isDark ? 'white' : cssParams.primaryColor
  return (
    <Flex
      className={styles.SocialsBlock}
      m={2}
      // className={styles.SocialsBar}
      // backgroundColor="primary.500"
      // width="220px"
      // height="72px"
      // borderRadius="4px 4px 0px 0px"
      justify="center"
      align="center"
      alignItems="stretch"
      justifyContent="center"
      marginBottom="2"
    >
      <Socials color={color} />
    </Flex>
  )
}

function ColumnItem({ className, children }: TElement) {
  return (
    <Text
      // marginY="3px"
      fontSize="sm"
      className={classnames(className, styles.ColumnItem)}
    >
      {children}
    </Text>
  )
}
interface TColumn extends TElement {
  title?: string
}
function Column({ title, children }: TColumn) {
  return (
    <Flex
      className={styles.Column}
      flex={1}
      flexDirection="column"
      alignItems="flex-start"
      // gridColumn={['1 / 7', '1 / 7', '1 / 7', '3 / 4', '3 / 4']}
      m="4"
      // maxW="200px"
      // paddingX="8"
      // marginTop="6"
      // mt={['12', '12', '12', '0', '0']}
    >
      {title && (
        <Text
          color="primary.500"
          fontWeight="bold"
          // fontSize="20px"
          mb="8px"
        >
          {title}
        </Text>
      )}
      {children}
    </Flex>
  )
}

interface TFooterWithContactsProps {
  className?: string
  // children?: TReactContent
  // title: TReactContent
  // description: TReactContent
}

function FooterWithContacts(props: TFooterWithContactsProps) {
  const { className } = props
  const { isDark } = React.useContext(GenericPageContext)
  const delimColor = isDark ? cssParams.primaryColors800 : cssParams.neutralColors500 // '#ddd'
  return (
    <Box as="footer" className={classnames(className, styles.root, isDark && styles.isDark)}>
      <Divider
        marginTop="20"
        // marginTop={['1in', '1.5in', '2in', '2.5in']}
        // marginBottom={['0.4in', '0.6in', '0.8in', '1in']}
        borderColor={delimColor}
      />
      <Wrapper className={styles.Wrapper}>
        <Flex
          className={styles.FlexWrapper}
          flexDirection={['column', 'column', 'row']}
          // gridColumn={['1 / 7', '1 / 7', '1 / 7', '1 / 3', '1 / 3']}
          // pl={['8', '8', '8', '24', '24']}
          align="center"
          // justifyContent="center"
        >
          <LogoBox isDark={isDark} />
          <Flex m="2" flex="1">
            {/*
            <Column title="Ссылки">
              <ColumnItem>WHMCS-bridge</ColumnItem>
              <ColumnItem>Search Domain</ColumnItem>
              <ColumnItem>My Account</ColumnItem>
              <ColumnItem>Shopping Cart</ColumnItem>
              <ColumnItem>Our Shop</ColumnItem>
            </Column>
            */}
            <Column
            // title="Контакты"
            >
              <Contacts ItemComponent={ColumnItem} textAlign={['center', 'center', 'left']} onDark={isDark} />
            </Column>
          </Flex>
          <Flex m="2">
            <SocialsBlock isDark={isDark} />
          </Flex>
          {/*
          <Column title="Информация">
            <ColumnItem>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</ColumnItem>
          </Column>
          */}
        </Flex>
      </Wrapper>
    </Box>
  )
}

export default FooterWithContacts
