/** @module PageLink
 *  @since 2022.01.29, 18:49
 *  @changed 2022.02.09, 21:59
 */

import * as React from 'react'
import NextLink from 'next/link'
import classnames from 'classnames'
import AnchorLink from 'react-anchor-link-smooth-scroll'
// import { Link as ChakraLink } from '@chakra-ui/react'

// import * as siteConfig from '@config/site'

import styles from './PageLink.module.less'
import { TReactContent } from '@utils/react-types'

export interface TPageLinkProps {
  href?: string
  className?: string
  children: TReactContent
  isExternal?: boolean
  onDark?: boolean
  onClick?: React.MouseEventHandler
  isFlex?: boolean
  title?: string
}

function LinkContent(props: TPageLinkProps) {
  const {
    children,
    href,
    onClick,
    title,
    // onDark,
    // isExternal,
  } = props
  const hasLink = !!href
  const isAnchorLink = hasLink && href.startsWith('#')
  if (!hasLink) {
    return (
      <a className={classnames(styles.Href, styles.EmptyLink)} onClick={onClick} title={title}>
        {children}
      </a>
    )
  } else if (isAnchorLink) {
    return (
      <AnchorLink className={classnames(styles.Href, styles.AnchorLink)} onClick={onClick} href={href} title={title}>
        {children}
      </AnchorLink>
    )
  } else {
    /* // External link with chakra link component?
     * else if (isExternal) {
     *   return (
     *     <ChakraLink href={href} isExternal>
     *       {children}
     *     </ChakraLink>
     *   )
     * }
     */
    return (
      <NextLink href={href}>
        <a className={classnames(styles.Href, styles.NextLink)} onClick={onClick} title={title}>
          {children}
        </a>
      </NextLink>
    )
  }
}
export default function PageLink(props: TPageLinkProps) {
  const { className, onDark, isFlex } = props
  const rootClassName = classnames(className, styles.root, onDark && styles.OnDark, isFlex && styles.Flex)
  return (
    <span className={rootClassName}>
      <LinkContent {...props} />
    </span>
  )
}
