/** @module LocationsItem
 *  @since 2022.01.16, 23:04
 *  @changed 2022.01.16, 23:04
 */

import React from 'react'
// import AnchorLink from 'react-anchor-link-smooth-scroll'
// import { Heading, Text } from '@chakra-ui/react'
import classnames from 'classnames'
import PageLink from '@ui-support/PageLink'

import styles from './LocationsItem.module.less'

interface TLocationsItemProps {
  className?: string
  // boxClassName?: string
  href?: string
  label: string
  handleNavLinkClick?: () => void
}

function LocationsItem(props: TLocationsItemProps) {
  const { className, label, href, handleNavLinkClick } = props
  let content: string | JSX.Element = label
  if (href) {
    content = (
      <PageLink
        href={href}
        // style={{ padding: '8px 0px' }}
        onClick={handleNavLinkClick}
      >
        {content}
      </PageLink>
    )
  }
  return <div className={classnames(className, styles.root)}>{content}</div>
}

export default LocationsItem
