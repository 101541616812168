/** @module GenericPageLayout
 *  @desc Renders parameters for `head` page part (title, meta-data, etc).
 *  @since 2021.12.27, 19:22
 *  @changed 2022.03.06, 10:55
 */

import * as React from 'react'
import config from '@config'
import { TReactContent } from '@utils/react-types'
import classnames from 'classnames'
import { Box } from '@chakra-ui/react'
import { THtmlHeaderProps } from '@layout/HtmlHeader/HtmlHeader'
import YandexMetric from '@src/3rd-part/YandexMetric'
import HtmlHeader from '@layout/HtmlHeader'
import NavBar from '@components/NavBar'
import FooterWithContacts from '@components/FooterWithContacts'
import TopCarousel from '@components/TopCarousel'
import { GenericPageContext } from './GenericPageContext'

import styles from './GenericPageLayout.module.scss'

export interface TGenericPageLayoutProps extends THtmlHeaderProps {
  children?: TReactContent
  isDark?: boolean
  showTopSplash?: boolean
  showTopCarousel?: boolean
  // className?: string
}

// const defaultProps = {
//   showTopSplash: config.app.showTopSplash,
//   showTopCarousel: config.app.showTopCarousel,
// }

// export const GenericPageContext = React.useMemo(() => React.createContext({}), [])

function GenericPageLayout(props: TGenericPageLayoutProps) {
  // NOTE: Get props from nextjs (as `pageProps`)
  const {
    children,
    isDark,
    showTopSplash = config.app.showTopSplash,
    showTopCarousel = config.app.showTopCarousel,
    ...restProps // Used as props for HtmlHeader (see THtmlHeaderProps)
  } = props
  // const GenericPageContext = React.useMemo(() => React.createContext({}), [])
  // prettier-ignore
  const contextData = React.useMemo(() => ({ isDark, }), [isDark])
  // prettier-ignore
  return (
    <GenericPageContext.Provider value={contextData}>
      <Box className={classnames(styles.root, isDark && styles.isDark)}>
        <HtmlHeader {...restProps} />
        <NavBar key="NavBar" showTopSplash={showTopSplash} />
        <Box className={styles.MainContainer}>
          {showTopCarousel && <TopCarousel />}
          {children}
        </Box>
        <FooterWithContacts />
        {config.build.isProd && <YandexMetric />}
      </Box>
    </GenericPageContext.Provider>
  )
}

export default GenericPageLayout
