/** @module YandexMetric
 *  @since 2022.01.30, 01:23
 *  @changed 2022.01.30, 01:28
 */

import { YMInitializer } from 'react-yandex-metrika'
import config from '@config'

export default function YandexMetric() {
  const { yandexMetricId, useExternalAnalytics } = config.site
  if (!useExternalAnalytics) {
    return null
  }
  return <YMInitializer accounts={[yandexMetricId]} />
}
