/** @module TopCarousel
 *  @since 2022.02.10, 00:50
 *  @changed 2022.02.18, 05:36
 */

import React, { SyntheticEvent, useState, useEffect, useRef, useCallback, useMemo } from 'react'
import {
  Heading,
  // Text,
  Box,
  Flex,
  Button,
  useDisclosure,
} from '@chakra-ui/react'
import classnames from 'classnames'
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Head from 'next/head'
import {
  TSlideData,
  slides,
  firstImage,
} from './TopCarouselData'
// import BuyApartmentRequestFormModal from '@forms/BuyApartmentRequestForm/BuyApartmentRequestFormModal'
import RequestDetailsFormModal from '@forms/RequestDetailsForm/RequestDetailsFormModal'
import OrderConsultationFormModal from '@forms/OrderConsultationForm/OrderConsultationFormModal'

import styles from './TopCarousel.module.less'
// NOTE: Just tested backound image urls from less (failed to locate correct asset resource url). Worked with scss.
// import styles2 from './TopCarousel.module.scss'

const defaultFormId = 'RequestDetails'

const onDark = true

// const defaultPadding = 10
// NOTE: paddingTop depends on `NavBar` height
// const paddingTop = [30, 50, 80, 100].map((height) => height + 'px')
// const paddingTop = [10, 30, 30, 40] // .map((height) => height [> + defaultPadding * 4 <] + 'px')
// const containerHeights = [300, 350, 400, 450, 500].map((height) => (height + 50) + 'px')
const containerHeights = [400, 425, 450, 475, 500].map((height) => 80 + height + 'px')
const marginTop = [0 /* 20, 30 */].map((height) => 25 + height + 'px')

// Automatically sliders change period
const autoChangingTimeout = 5000

const useAutoChangeSlides = false || config.build.isProd

interface TSlideCardItem extends TSlideData {
  handleSlideAction?: (ev: SyntheticEvent<HTMLElement>) => void
}
function SlideCardContent(data: TSlideCardItem) {
  // prettier-ignore
  const {
    slideId,
    title,
    smallTitle,
    content,
    extraContent,
    handleSlideAction,
    actionText = 'Подробнее',
  } = data
  if (!slideId) {
    return null
  }
  /* console.log('@:TopCarousel:SlideCardContent', {
   *   slideId,
   *   handleSlideAction,
   * })
   */
  return (
    <>
      <Box
        className={classnames(/* styles2.SlideCardBg, */ styles.SlideCardBg)}
        backgroundPosition={['center', null, 'right,center']}
        backgroundSize={['auto', null, 'cover']}
        // backgroundImage={slideBg}
        width={['100%', '100%', '60%', '50%']}
        maxWidth="600px"
      >
        <Flex
          className={styles.SlideCardContent}
          padding={[5, 20]}
          marginTop={marginTop}
          // paddingTop={paddingTop}
          // marginTop={paddingTop}
          // paddingBottom={defaultPadding}
          gap={1}
        >
          {title && (
            <Heading
              as="h2"
              className={styles.SlideTitle}
              // fontSize="2xl"
              fontSize={['xl', '2xl', '3xl', '4xl']}
              fontWeight="normal"
            >
              {title}
            </Heading>
          )}
          {smallTitle && (
            <Heading
              as="h3"
              className={styles.SlideSmallTitle}
              // fontSize="2xl"
              fontSize={['md', 'lg', 'xl', 'xl']}
              fontWeight="normal"
            >
              {smallTitle}
            </Heading>
          )}
          {content && (
            <Box className={styles.SlideText} fontSize={['sm', 'md']}>
              {content}
            </Box>
          )}
          {extraContent && (
            <Box className={classnames(styles.SlideExtraBox, styles.SlideExtraText)} fontSize={['sm']}>
              {extraContent}
            </Box>
          )}
          <Box
            className={styles.SlideAction}
            mt="2"
            // mb={[0, 0, extraContent ? '80px' : 0]}
          >
            <Button
              id={slideId}
              colorScheme="primary"
              // colorScheme="secondary"
              onClick={handleSlideAction}
              // leftIcon={<FontAwesomeIcon icon={faCheck} width="16" />}
            >
              {actionText}
            </Button>
          </Box>
        </Flex>
      </Box>
      {/* // Temp.unused: right-float extra content block
      {extraContent && (
        <Flex className={styles.SlideCardExtraFloatBox} display={['none', null, 'block']}>
          <Box className={styles.SlideExtraText} fontSize={['sm']}>
            {extraContent}
          </Box>
        </Flex>
      )}
      */}
    </>
  )
}

function SlideCard(data: TSlideCardItem) {
  const { slideId, img } = data
  // prettier-ignore
  return (
    <Box className={styles.SlideCard} backgroundImage={img} flex="none" id={slideId}>
      {slideId && <SlideCardContent {...data} />}
    </Box>
  )
}

function Dot({ slideNo, currentSlide, setSlide }) {
  const isCurrent = currentSlide === slideNo
  return (
    <Box
      className={classnames(styles.Dot, isCurrent && styles.DotCurrent)}
      id={slideNo}
      // boxSize={['8px', '10px', '14px']}
      onClick={isCurrent ? null : setSlide}
    />
  )
}

function Navigation({ slides, currentSlide, setSlide, setPrevSlide, setNextSlide }) {
  // prettier-ignore
  return (
    <>
      <Flex
        className={classnames(styles.Arrow, styles.ArrowLeft)}
        display={['none', 'flex']}
        onClick={setPrevSlide}
      >
        <FontAwesomeIcon icon={faChevronLeft} width="16" />
      </Flex>
      <Flex
        className={classnames(styles.Arrow, styles.ArrowRight)}
        display={['none', 'flex']}
        onClick={setNextSlide}
      >
        <FontAwesomeIcon icon={faChevronRight} width="16" />
      </Flex>
      <Flex className={classnames(styles.Dots)}>
        {slides.map((_, slideNo) => (
          <Dot key={'dot-' + slideNo} slideNo={slideNo} currentSlide={currentSlide} setSlide={setSlide} />
        ))}
      </Flex>
    </>
  )
}

function SlidesContainer({ slides, offsetPx, currentSlide, handleSlideAction }) {
  const displaySlideBox = -(currentSlide + 1)
  const marginLeft = `calc( ${displaySlideBox * 100}% + ${offsetPx}px)`
  // prettier-ignore
  return (
    <Flex
      id="TopCarouselEventsTarget"
      className={styles.SlidesContainer}
      minHeight={containerHeights}
      ml={marginLeft}
    >
      {/* NOTE: Adde 'fake' zero slider for hide relayout effect on drag left on first slide */}
      <SlideCard key={'EmptySlide'} slideId="" img={null} />
      {slides.map((data, slideNo) => (
        <SlideCard key={'slide-' + slideNo} {...data} handleSlideAction={handleSlideAction} />
      ))}
    </Flex>
  )
}

interface TTopCarouselProps {
  className?: string
}
interface TCarouselMemo {
  autoChangingTimer?: NodeJS.Timer
  setPrevSlide?: () => void
  setNextSlide?: () => void
}
function TopCarousel(props: TTopCarouselProps) {
  const rootClassName = classnames(props.className, styles.root, onDark && styles.OnDark)
  const [offsetPx, setOffsetPx] = useState(0)
  const [currentSlide, setCurrentSlide] = useState(0)
  const { isOpen, onOpen, onClose } = useDisclosure()
  /*
   * const doOpen = useCallback((arg) => {
   *   console.log('@:TopCarousel:TopCarousel', {
   *     arg,
   *   })
   *   debugger
   *   onOpen()
   * }, [])
   */
  const [isHovered, setHovered] = useState(false)
  const [isTouch, setTouch] = useState(false)
  const [isDrag, setDrag] = useState(false)
  const memo = useMemo(() => {
    return {
      autoChangingTimer: null,
    } as TCarouselMemo
  }, [])
  const domRef = useRef(null)
  const slidesCount = slides.length
  const currentSlideData = slides[currentSlide]
  const currentFormId = currentSlideData.actionFormId || defaultFormId
  /* console.log('@:TopCarousel:render', {
   *   currentFormId,
   *   currentSlide,
   *   isHovered,
   *   // autoChangingTimer,
   *   memo,
   * })
   */
  function setPrevSlide() {
    setCurrentSlide((currentSlide) => (currentSlide === 0 ? slidesCount - 1 : currentSlide - 1))
    setOffsetPx(0)
  }
  function setNextSlide() {
    setCurrentSlide((currentSlide) => (currentSlide === slidesCount - 1 ? 0 : currentSlide + 1))
    setOffsetPx(0)
  }
  function setSlide(ev) {
    const slideNo = Number(ev.target.id)
    setCurrentSlide(slideNo)
    setOffsetPx(0)
  }
  // Save/update change slide callbacks...
  memo.setPrevSlide = setPrevSlide
  memo.setNextSlide = setNextSlide
  const startAutoChangingTimer = useCallback(function startAutoChangingTimer() {
    const { autoChangingTimer } = memo
    if (!autoChangingTimer) {
      const nextTimer = setInterval(setNextSlide, autoChangingTimeout)
      // console.log('@:TopCarousel:startAutoChangingTimer', {
      //   // autoChangingTimer,
      //   nextTimer,
      // })
      memo.autoChangingTimer = nextTimer
    }
  }, [])
  const stopAutoChangingTimer = useCallback(function stopAutoChangingTimer() {
    const { autoChangingTimer } = memo
    if (autoChangingTimer) {
      // console.log('@:TopCarousel:stopAutoChangingTimer', {
      //   autoChangingTimer,
      // })
      clearInterval(autoChangingTimer)
      memo.autoChangingTimer = null
    }
  }, [])
  // prettier-ignore
  useEffect(function updateAutoChangingStatus() {
    if (useAutoChangeSlides) {
      const useAutoChanging = !isOpen && !isHovered && !isTouch && !isDrag
      // console.log('@:TopCarousel:updateAutoChangingStatus', {
      //   useAutoChanging,
      //   isOpen,
      //   isHovered,
      //   isTouch,
      //   isDrag,
      // })
      // Update auto changing timer...
      if (useAutoChanging) {
        startAutoChangingTimer()
      } else {
        stopAutoChangingTimer()
      }
    }
  }, [isOpen, isHovered, isTouch, isDrag, startAutoChangingTimer, stopAutoChangingTimer])
  // prettier-ignore
  useEffect(function onMount() {
    // Set mouse event handlers...
    const domNode = domRef && domRef.current
    // Get eventss target dom node...
    const clickTargets = domNode && domNode.getElementsByClassName(styles.SlidesContainer)
    const clickTarget = clickTargets && clickTargets[0]
    // Touch events...
    let touchX = 0, touchStartX = 0, touchOffset = 0
    function handleTouchStart(ev) {
      const { changedTouches } = ev
      const tob = changedTouches && changedTouches[0]
      touchStartX = tob && tob.clientX
      // console.log('@:TopCarousel:handleTouchStart', {
      //   touchStartX,
      // })
      setTouch(true)
    }
    function handleTouchEnd(ev) {
      setTouch(false)
      setOffsetPx(0)
      const { changedTouches } = ev
      const tob = changedTouches && changedTouches[0]
      touchX = tob && tob.clientX
      touchOffset = touchX - touchStartX
      const absOffset = Math.abs(touchOffset)
      // console.log('@:TopCarousel:handleTouchEnd', {
      //   absOffset,
      //   touchOffset,
      //   touchX,
      //   touchStartX,
      // })
      if (absOffset > 80) {
        if (touchOffset > 0) {
          memo.setPrevSlide && memo.setPrevSlide()
        }
        else {
          memo.setNextSlide && memo.setNextSlide()
        }
      }
    }
    function handleTouchCancel() {
      // console.log('@:TopCarousel:handleTouchCancel')
      setTouch(false)
      setOffsetPx(0)
    }
    function handleTouchMove(ev) {
      const { changedTouches } = ev
      const tob = changedTouches && changedTouches[0]
      touchX = tob && tob.clientX
      touchOffset = touchX - touchStartX
      setOffsetPx(touchOffset)
      // console.log('@:TopCarousel:handleTouchMove', {
      //   touchOffset,
      //   touchX,
      //   touchStartX,
      // })
    }
    // Mouse leave/enter...
    let isMouseIn = false
    function handleMouseEnter() {
      // console.log('@:TopCarousel:handleMouseEnter')
      setHovered(true)
      isMouseIn = true
    }
    function handleMouseLeave() {
      // console.log('@:TopCarousel:handleMouseLeave')
      setHovered(false)
      isMouseIn = false
    }
    let dragX = 0, dragStartX = 0, dragOffset = 0
    function handleMouseMove(ev) {
      dragX = ev.clientX
      dragOffset = dragX - dragStartX
      // console.log('@:TopCarousel:handleMouseMove', {
      //   dragOffset,
      //   dragX,
      //   dragStartX,
      // })
      setOffsetPx(dragOffset)
    }
    function handleMouseDown(ev) {
      setDrag(true)
      dragStartX = ev.clientX
      // console.log('@:TopCarousel:handleMouseDown', {
      //   dragStartX,
      // })
      window.addEventListener('mousemove', handleMouseMove, false)
      window.addEventListener('mouseup', handleMouseUp, false)

    }
    function handleMouseUp(ev) {
      setDrag(false)
      setOffsetPx(0)
      dragX = ev.clientX
      dragOffset = dragX - dragStartX
      // console.log('@:TopCarousel:handleMouseUp', {
      //   dragOffset,
      //   dragX,
      //   dragStartX,
      // })
      window.removeEventListener('mousemove', handleMouseMove, false)
      window.removeEventListener('mouseup', handleMouseUp, false)
      if (isMouseIn) {
        const absOffset = Math.abs(dragOffset)
        // console.log('@:TopCarousel:handleMouseUp: accept drag', {
        //   dragOffset,
        //   dragX,
        //   dragStartX,
        // })
        // Accept drag
        if (absOffset > 80) {
          if (dragOffset > 0) {
            memo.setPrevSlide && memo.setPrevSlide()
          }
          else {
            memo.setNextSlide && memo.setNextSlide()
          }
        }
      }
    }
    // Set all event handlers...
    if (domNode) {
      domNode.addEventListener('mouseenter', handleMouseEnter, false)
      domNode.addEventListener('mouseleave', handleMouseLeave, false)
      domNode.addEventListener('touchstart', handleTouchStart, false)
      domNode.addEventListener('touchend', handleTouchEnd, false)
      domNode.addEventListener('touchcancel', handleTouchCancel, false)
      domNode.addEventListener('touchmove', handleTouchMove, false)
      clickTarget.addEventListener('mousedown', handleMouseDown, false)
    }
    return function onUnmount() {
      stopAutoChangingTimer()
      if (domNode) {
        domNode.removeEventListener('mouseenter', handleMouseEnter, false)
        domNode.removeEventListener('mouseleave', handleMouseLeave, false)
        domNode.removeEventListener('touchstart', handleTouchStart, false)
        domNode.removeEventListener('touchend', handleTouchEnd, false)
        domNode.removeEventListener('touchcancel', handleTouchCancel, false)
        domNode.removeEventListener('touchmove', handleTouchMove, false)
        clickTarget.removeEventListener('mousedown', handleMouseDown, false)
      }
    }
  }, [stopAutoChangingTimer, startAutoChangingTimer])
  const SlideId = slides[currentSlide] && slides[currentSlide].slideId
  // prettier-ignore
  return (
    <Flex
      className={rootClassName}
      ref={domRef}
    >
      <Head>
        {/* Preload first carousel image */}
        {/*
        <link rel="preload" as="image" href={slideBg} />
        */}
        <link rel="preload" as="image" href={firstImage} />
      </Head>
      <RequestDetailsFormModal
        isOpen={isOpen && currentFormId === 'RequestDetails'}
        onClose={onClose}
        FromPage={SlideId}
      />
      <OrderConsultationFormModal
        isOpen={isOpen && currentFormId === 'OrderConsultation'}
        onClose={onClose}
        FromPage={SlideId}
      />
      <Flex className={styles.Wrapper}>
        <SlidesContainer
          slides={slides}
          offsetPx={offsetPx}
          currentSlide={currentSlide}
          // handleSlideAction={handleSlideAction}
          handleSlideAction={onOpen}
        />
        <Navigation
          slides={slides}
          currentSlide={currentSlide}
          setSlide={setSlide}
          setPrevSlide={setPrevSlide}
          setNextSlide={setNextSlide}
        />
      </Flex>
    </Flex>
  )
}

export default TopCarousel
