/** @module Contacts
 *  @since 2022.01.16, 23:50
 *  @changed 2022.01.16, 23:50
 */

import React from 'react'
import classnames from 'classnames'
import { StyleProps, Box } from '@chakra-ui/react'
import config from '@config'
// import { Text, Link } from '@chakra-ui/react'
import { TReactContent } from '@utils/react-types'
import PageLink from '@ui-support/PageLink'

import styles from './Contacts.module.less'

// import ContactsItem from '@components/Contacts/ContactsItem'

interface TContactsItemProps {
  className?: string
  children?: TReactContent
}

function ContactsItem(props: TContactsItemProps) {
  const { className, children } = props
  return <div className={classnames(className, styles.ContactsItem)}>{children}</div>
}

interface TContactsProps extends StyleProps {
  className?: string
  itemClassName?: string
  ItemComponent?: React.ComponentType<TContactsItemProps>
  onDark?: boolean
  handleNavLinkClick?: () => void
}

function Contacts(props: TContactsProps) {
  const {
    phoneNumber,
    emailAddress,
    emailAddress2,
    // instagramUrl,
    // instagramName,
  } = config.site
  const { className, itemClassName, ItemComponent, onDark, handleNavLinkClick, ...restProps } = props
  const Item = ItemComponent || ContactsItem
  return (
    <Box {...restProps} className={classnames(className, styles.root, onDark && styles.onDark)}>
      <Item className={itemClassName}>
        <span className={styles.Label}>Телефон:</span>{' '}
        <PageLink isExternal href={'tel:' + phoneNumber} onClick={handleNavLinkClick}>
          {phoneNumber}
        </PageLink>
      </Item>
      <Item className={itemClassName}>
        <span className={styles.Label}>E-mail:</span>{' '}
        <PageLink isExternal href={'mailto:' + emailAddress} onClick={handleNavLinkClick}>
          {emailAddress}
        </PageLink>
        ,{' '}
        <PageLink isExternal href={'mailto:' + emailAddress2} onClick={handleNavLinkClick}>
          {emailAddress2}
        </PageLink>
      </Item>
      <Item className={classnames(itemClassName, styles.ExtraItem)}>
        <PageLink href={'/agreement'} onClick={handleNavLinkClick}>
          Политика конфиденциальности
        </PageLink>
      </Item>
    </Box>
  )
}

export default Contacts
