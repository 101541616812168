/** @module OrderConsultationFormSection
 *  @since 2022.01.22, 22:22
 *  @changed 2022.01.28, 22:47
 */

import React from 'react'
import config from '@config'
import BasicForm from '@forms-support/BasicForm'

// import styles from './OrderConsultationFormSection.module.less'
// const styles: Record<string, any> = {} // Stub for module styles

export const title = 'Заказать консультацию'

const collectionId = 'Consultations'
const successText = 'Спасибо, мы обязательно Вам перезвоним'
const introText = 'Мы ответим на любые Ваши вопросы о продаже недвижимости. Заполните форму ниже, и мы Вам обязательно перезвоним.'

function getFileds({ FromPage, isDebug }) {
  return [
    {
      id: 'FromPage',
      name: 'Слайд',
      isHidden: true,
      // isRequired: true,
      defaultValue: FromPage,
    },
    {
      id: 'FirstName',
      name: 'Имя',
      isRequired: true,
      defaultValue: isDebug ? 'test' : '',
    },
    {
      id: 'LastName',
      name: 'Фамилия',
      // isRequired: true,
      defaultValue: isDebug ? 'test' : '',
    },
    /* // UNUSED: testField
    {
      id: 'testField',
      name: 'Test',
      isRequired: true,
      defaultValue: isDebug ? 'test' : '',
    },
    */
    {
      id: 'PhoneNumber',
      name: 'Номер телефона',
      isRequired: true,
      defaultValue: isDebug ? 'test' : '',
    },
  ]
}

export interface TOrderConsultationFormParams {
  FromPage?: string
  isDark?: boolean
  onSuccess?: () => void
}

function OrderConsultationForm(props: TOrderConsultationFormParams) {
  const { FromPage, onSuccess, isDark } = props
  const isDebug = config.build.isDevServer
  const fields = React.useMemo(() => getFileds({ FromPage, isDebug}), [FromPage, isDebug])
  /* console.log('@:OrderConsultationFormSection:OrderConsultationForm', {
   *   FromPage,
   *   collectionId,
   *   fields,
   *   successText,
   *   introText,
   *   onSuccess,
   *   isDark,
   * })
   */
  const formProps = {
    collectionId,
    fields,
    successText,
    introText,
    onSuccess,
    onDark: isDark,
  }
  return <BasicForm {...formProps} />
}

export default OrderConsultationForm
