/** @module TopSplash
 *  @since 2022.01.04, 20:38
 *  @changed 2022.01.15, 21:41
 */

import React from 'react'
import { Flex, Box } from '@chakra-ui/react'
import classnames from 'classnames'
// import * as siteConfig from '@config/site'
import PageSection from '@ui-elements/PageSection'
import HamburgerBox from '@ui-elements/HamburgerBox'
import PageLink from '@ui-support/PageLink'
import PageText from '@ui-support/PageText'
import Logo from '@components/Logo'
import PhoneBox from '@ui-elements/PhoneBox/PhoneBox'

import styles from './TopSplash.module.less'

import headerBg from '@global/assets/TopSplash/splash-bg-001.jpg'

interface TTopNavBarProps {
  className?: string
  // showNavBar?: boolean
  onDark?: boolean
  handleNavLinkClick?: () => void
}

function TopInfoBox(props) {
  // const { phoneNumber } = siteConfig
  const {
    onDark,
    className,
    display, // = ['none', 'block', 'block', 'block'],
    // children = phoneNumber,
  } = props
  return (
    <Box
      className={classnames(className, styles.TopInfoBox)}
      // NOTE: Sync breakpoints with `NavSideOverlay`
      display={display}
      p={4}
    >
      {/*
      <Flex align="center" justify="flex-end">
        <Text whiteSpace="nowrap" fontSize="xl" className={styles.TopInfoBoxPhone}>
          <PageLink isExternal href={'tel:' + phoneNumber} onDark={onDark}>
            {phoneNumber}
          </PageLink>
        </Text>
      </Flex>
      */}
      <PhoneBox onDark={onDark} />
      <Flex align="center" justify="flex-end" p={2}>
        <PageText whiteSpace="nowrap" onDark={onDark} className={styles.TopInfoBoxWorkTime}>
          Рады слышать вас с 9 до 23
        </PageText>
      </Flex>
    </Box>
  )
}

function TopNavBar(props: TTopNavBarProps) {
  const { onDark, className, handleNavLinkClick } = props
  return (
    <Flex
      className={classnames(className, styles.TopNavBar)}
      flexDirection="row"
      align="flex-start"
      justify="space-between"
    >
      <HamburgerBox onDark={onDark} handleNavLinkClick={handleNavLinkClick} />
      <TopInfoBox onDark={onDark} />
    </Flex>
  )
}

function TopLogoBar(props) {
  const { onDark, className } = props
  const isFlex = true
  return (
    <Flex
      className={classnames(className, styles.TopLogoBar)}
      flex="1"
      flexDirection="row"
      align="center"
      justify="center"
      height={['20vh', '30vh', '40vh', '50vh']}
      // p={5}
    >
      <PageLink
        className={styles.LogoLink}
        href="/"
        // href="#TopSplash"
        isFlex={isFlex}
      >
        <Logo className={styles.LogoSign} onDark={onDark} isHorizontal isFlex={isFlex} />
      </PageLink>
    </Flex>
  )
}

interface TTopSplashProps {
  className?: string
  showNavBar?: boolean
  onDark?: boolean
  handleNavLinkClick?: () => void
}

function TopSplash(props: TTopSplashProps) {
  const {
    onDark,
    className,
    handleNavLinkClick,
    showNavBar, // Is main NavBar visible now?
  } = props
  // const { primaryMotto } = siteConfig
  const rootClassName = classnames([className, styles.root, onDark && styles.OnDark, showNavBar && styles.showNavBar])
  return (
    <PageSection
      className={rootClassName}
      noTopSpace
      id="TopSplash"
      backgroundImage={headerBg}
      backgroundPosition="center"
      backgroundRepeat="no-repeat"
      backgroundSize="cover"
    >
      <Flex className={styles.FlexLayout} flexDirection="column" align="stretch" justify="center">
        <TopNavBar onDark={onDark} handleNavLinkClick={handleNavLinkClick} />
        <TopLogoBar onDark={onDark} />
      </Flex>
    </PageSection>
  )
}

export default TopSplash
