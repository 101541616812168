/** @module PhoneBox
 *  @since 2022.01.29, 21:48
 *  @changed 2022.01.29, 21:49
 */

import React from 'react'
import classnames from 'classnames'
import { Button, Box, Flex, Text, useDisclosure } from '@chakra-ui/react'
import * as siteConfig from '@config/site'
import PageLink from '@ui-support/PageLink'
import { TReactContent } from '@utils/react-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone } from '@fortawesome/free-solid-svg-icons'
// import AgreementModal from '@components/Agreement/Modal'
// import BuyApartmentFormModal from '@forms/BuyApartmentForm/BuyApartmentFormModal'
import OrderCallbackFormModal from '@forms/OrderCallbackForm/OrderCallbackFormModal'

import styles from './PhoneBox.module.less'

interface TPhoneBoxProps {
  // children?: TReactContent
  className?: string
  onDark?: boolean
  display?: any
  // display?: Pick<ChakraProps, 'display'> // LayoutProps.display
  // display?: Pick<LayoutProps, 'display'> // LayoutProps.display
}

function PhoneBox(props: TPhoneBoxProps) {
  const { phoneNumber } = siteConfig
  const {
    onDark,
    className,
    display, // = ['none', 'block', 'block', 'block'],
    // children = phoneNumber,
  } = props
  const { isOpen, onOpen, onClose } = useDisclosure()
  /* // function openAgreementModal(ev) {
   *   ev.preventDefault()
   *   onOpen()
   *   return false
   * }
   */
  return (
    <Box
      className={classnames(className, styles.root, onDark && styles.OnDark)}
      // NOTE: Sync breakpoints with `NavSideOverlay`
      display={display}
    >
      <Flex align="center" justify="flex-end" p="2" columnGap={5}>
        <Text
          className={styles.PhoneLink}
          whiteSpace="nowrap"
          color="secondary.500"
          fontSize={['md', 'lg', 'xl']}
          // fontSize={['md', 'md', 'lg', 'xl']}
          // display={['block', 'none', 'block']}
        >
          <FontAwesomeIcon style={{ display: 'inline-block' }} icon={faPhone} width="16" height="16" />{' '}
          <PageLink isExternal href={'tel:' + phoneNumber} onDark={onDark}>
            {phoneNumber}
          </PageLink>
        </Text>
        <Box className={styles.CallbackButton}>
          <Button colorScheme="secondary" onClick={onOpen}>
            Обратный звонок
          </Button>
        </Box>
      </Flex>
      <OrderCallbackFormModal isOpen={isOpen} onClose={onClose} />
    </Box>
  )
}

export default PhoneBox
