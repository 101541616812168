/** @module AgreementModal
 *  @since 2022.02.02, 19:48
 *  @changed 2022.02.02, 19:48
 */

import React from 'react'
import classnames from 'classnames'
import { TReactContent } from '@utils/react-types'
import AgreementContent, { title } from '../Content/AgreementContent'
import BasicModal from '@ui-elements/BasicModal'

import styles from './AgreementModal.module.less'

interface TAgreementModalProps {
  className?: string
  isOpen?: boolean
  onClose?: () => void
  agreeAction?: () => void // ???
  children?: TReactContent
}

function AgreementModal(props: TAgreementModalProps) {
  const { isOpen, onClose, agreeAction, className } = props
  const handleDefaultAction = () => {
    onClose()
    if (agreeAction) {
      agreeAction()
    }
  }
  const rootClassName = classnames(className, styles.root)
  const modalProps = {
    id: 'AgreementModal',
    isOpen,
    onClose,
    defaultAction: handleDefaultAction,
    contentClassName: rootClassName,
    defaultText: 'Даю согласие',
    title,
    size: '4xl',
    // contentClassName,
    // bodyClassName,
  }
  return (
    <BasicModal {...modalProps}>
      <AgreementContent />
    </BasicModal>
  )
}

export default AgreementModal
