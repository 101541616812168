/** @module TopCarouselConstants
 *  @since 2022.02.10, 23:13
 *  @changed 2022.02.14, 22:50
 */

import React from 'react'
import { TReactContent } from '@utils/react-types'
// import { TSlideData } from './TopCarouselConstants'

import slideBg from './assets/side-bg.png'

// import splash001 from '@global/assets/TopSplash/splash-bg-001.jpg'
// import slide001 from './assets/slide001-01.jpg'
// import slide002 from './assets/slide002-01.jpg'

import slide0 from './img/slide-0/how-to-find-the-best-cityscape-locations-1.jpg'
import slide1 from './img/slide-1.jpg'
import slide2 from './img/slide-2.jpg'
import slide3 from './img/slide-3.jpg'

export { slideBg }

export interface TSlideData {
  slideId: string
  // slideName?: string
  title?: TReactContent
  smallTitle?: TReactContent
  content?: TReactContent
  extraContent?: TReactContent
  img?: string
  actionText?: string
  actionFormId?: string // Default: 'RequestDetails'
}

export const slides: TSlideData[] = [
  {
    slideId: 'slide-0',
    // img: 'https://img2.nmarket.pro/photo/pid/87ff1894-60bf-4904-8292-f82207b9cacc?type=jpg&w=1600&h=1600&wpsid=9',
    img: slide0,
    title: <>Компания <nobr>«Март-Недвижимость»</nobr></>,
    smallTitle: 'Продайте свою квартиру с нашей помощью!',
    extraContent: (
      <>
        <ul>
          <li>Знаем 50+ способов быстро продать квартиру</li>
          <li>Проконсультируем бесплатно</li>
          <li>Возьмем на себя все сложности по отбору потенциальных покупателей</li>
          <li>Пройдем с вами все этапы сделки</li>
          <li>Подберем квартиру взамен старой</li>
        </ul>
      </>
    ),
    actionText: 'Заказать консультацию',
    actionFormId: 'OrderConsultation',
  },
  {
    slideId: 'slide-1',
    // img: 'https://img2.nmarket.pro/photo/pid/87ff1894-60bf-4904-8292-f82207b9cacc?type=jpg&w=1600&h=1600&wpsid=9',
    img: slide1,
    smallTitle: (
      <>Новые квартиры с&nbsp;восхитительными видами в&nbsp;окружении водоемов и&nbsp;парков на&nbsp;юге Москвы</>
    ),
    content: (
      <>
        {/*
        <p>Новые квартиры с восхитительными видами в окружении водоемов и парков на юге Москвы</p>
        */}
        <p>Подходит под инвестиции</p>
        <p>Доходность 20-25%</p>
      </>
    ),
    extraContent: (
      <>
        <ul>
          <li>Студия от 8,9 млн</li>
          <li>1-комн от 10 млн</li>
          <li>2-комн от 16,2 млн</li>
          <li>3-комн от 29,2 млн</li>
        </ul>
      </>
    ),
  },
  {
    slideId: 'slide-2',
    // img: 'https://img2.nmarket.pro/photo/pid/21f370c7-7939-409b-b70f-f1f817ffee27?type=jpg&w=1600&h=1600&wpsid=9',
    img: slide2,
    smallTitle: <>ЖК для жизни, отдыха и&nbsp;учебы: музеи, театры, торговые центры</>,
    content: (
      <>
        {/*
        <p>ЖК для жизни, отдыха и учебы: музеи, театры, торговые центры</p>
        */}
        <p>Комфорт и безопасность на берегу Москвы-реки</p>
        <p>Для детей – одна из лучших школ Москвы</p>
        <p>Подземный паркинг</p>
      </>
    ),
    extraContent: (
      <>
        <ul>
          <li>Студия от 10,5 млн</li>
          <li>1-комн от 14 млн</li>
          <li>2-комн от 18,7 млн</li>
          <li>3-комн от 26,5 млн</li>
        </ul>
      </>
    ),
  },
  {
    slideId: 'slide-3',
    // slideName: 'ЖК Hill8',
    // img: 'https://img2.nmarket.pro/photo/pid/0f9bae1d-8e56-47de-bd92-30ea767dcff2?type=jpg&w=1600&h=1600&wpsid=9',
    img: slide3,
    smallTitle: <>Квартиры недалеко от&nbsp;центра Москвы</>,
    content: (
      <>
        {/*
        <p>Квартиры с системой «Умный дом» недалеко от центра Москвы</p>
        */}
        <p>Подземный паркинг с зарядкой для электромобилей</p>
        <p>Круглосуточная охрана и видеонаблюдение</p>
      </>
    ),
    extraContent: (
      <>
        <ul>
          <li>Студия от 17,2 млн</li>
          <li>1-комн от 21,1 млн</li>
          <li>2-комн от 40,1 млн</li>
          <li>3-комн от 50,4 млн</li>
        </ul>
      </>
    ),
  },
  /*
  {
    img: slide001,
    title: 'Second Slide',
    content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
  },
  {
    img: slide002,
    title: 'Third Slide',
    content: 'Praesent commodo cursus magna, vel scelerisque nisl consectetur.',
  },
  {
    img: 'https://images.pexels.com/photos/1142950/pexels-photo-1142950.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
    title: 'Fourth Slide',
    content: 'Nulla vitae elit libero, a pharetra augue mollis interdum.',
  },
  {
    img: 'https://images.pexels.com/photos/3124111/pexels-photo-3124111.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
    title: 'Fifth Slide',
    content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
  },
  {
    img: splash001,
    title: 'Last Slide',
    content: 'Nulla vitae elit libero, a pharetra augue mollis interdum.',
  },
  */
]

export const firstImage = slides[0].img
