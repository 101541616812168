/** @module AgreementContent
 *  @since 2022.02.02, 19:48
 *  @changed 2022.02.02, 23:14
 */

import React from 'react'
import { Box, Text } from '@chakra-ui/react'
import classnames from 'classnames'
import { TReactContent } from '@utils/react-types'
import config from '@config'
import PageLink from '@ui-support/PageLink'

import styles from './AgreementContent.module.less'

export const title = 'Политика конфиденциальности компании «Март-Недвижимость»'

interface TAgreementContentProps {
  className?: string
}

function AgreementContent(props: TAgreementContentProps) {
  const { className } = props
  const { emailAddress } = config.site
  return (
    <Box className={classnames(className, styles.root)}>
      <Text className={styles.Text}>
        <span className={styles.Label}>1.</span> Общие положения политики
      </Text>
      <Text className={styles.Text}>
        <span className={styles.Label}>1.1.</span> Настоящая Политика действует в отношении всех персональных данных,
        которые компания «Март-Недвижимость» может получить от Пользователя во время использования аккаунта или сайта
        компании «Март-Недвижимость».
      </Text>
      <Text className={styles.Text}>
        <span className={styles.Label}>2.</span> Состав и цели обработки персональных данных
      </Text>
      <Text className={styles.Text}>
        <span className={styles.Label}>2.1.</span> Основная цель обработки персональных данных – выполнение обязательств
        перед Пользователем.
      </Text>
      <Text className={styles.Text}>
        <span className={styles.Label}>3.</span> Принципы и способы обработки персональных данных
      </Text>
      <Text className={styles.Text}>
        <span className={styles.Label}>3.1.</span> При обработке персональных данных компания «Март-Недвижимость»:
      </Text>
      <Text className={styles.Text}>
        <span className={styles.Label}>3.1.1.</span> Обеспечивает законность целей и способов обработки персональных
        данных;
      </Text>
      <Text className={styles.Text}>
        <span className={styles.Label}>3.1.2.</span> Гарантирует соответствие целей обработки персональных данных
        заранее определенным и заявленным при сборе персональных данных целям.
      </Text>
      <Text className={styles.Text}>
        <span className={styles.Label}>4.</span> Правила обработки персональных данных
      </Text>
      <Text className={styles.Text}>
        <span className={styles.Label}>4.1.</span> Персональные данные получаются непосредственно от Пользователей в
        процессе пользования аккаунтами или сайта компании «Март-Недвижимость».
      </Text>
      <Text className={styles.Text}>
        <span className={styles.Label}>5.</span>Обеспечение безопасности персональных данных
      </Text>
      <Text className={styles.Text}>
        <span className={styles.Label}>5.1.</span> Компания «Март-Недвижимость» принимает все необходимые меры защиты, в
        том числе предусмотренные законодательством Российской Федерации. Компания «Март-Недвижимость» обеспечивает
        конфиденциальность и безопасность персональных данных.
      </Text>
      <Text className={styles.Text}>
        <span className={styles.Label}>6.</span> Права Пользователей в отношении своих персональных данных
      </Text>
      <Text className={styles.Text}>
        <span className={styles.Label}>6.1.</span> Пользователи в соответствии с Федеральным законом от 27.07.2006 г. №
        152-ФЗ «О персональных данных» имеют право запрашивать сведения о своих обрабатываемых в компании
        «Март-Недвижимость» персональных данных.
      </Text>
      <Text className={styles.Text}>
        <span className={styles.Label}>7.</span> Уничтожение персональных данных
      </Text>
      <Text className={styles.Text}>
        <span className={styles.Label}>7.1.</span> Персональные данные, обрабатываемые компанией «Март-Недвижимость»,
        уничтожаются:
      </Text>
      <Text className={styles.Text}>
        <span className={styles.Label}>7.1.1.</span> По достижении целей их обработки или в случае утраты необходимости
        в их достижении в срок, не превышающий тридцати дней с момента достижения цели обработки персональных данных,
        если иное не предусмотрено договором, стороной которого, выгодоприобретателем или поручителем, по которому
        является субъект персональных данных;
      </Text>
      <Text className={styles.Text}>
        <span className={styles.Label}>7.1.2.</span> В случае выявления неправомерной обработки персональных данных
        компанией «Март-Недвижимость» в срок, не превышающий десяти рабочих дней с момента выявления неправомерной
        обработки персональных данных;
      </Text>
      <Text className={styles.Text}>
        <span className={styles.Label}>7.1.3.</span> В случае отзыва субъектом персональных данных согласия на обработку
        его персональных данных, если сохранение персональных данных более не требуется для целей обработки персональных
        данных, в срок, не превышающий тридцати дней с даты поступления указанного отзыва, если иное не предусмотрено
        договором, стороной которого, выгодоприобретателем или поручителем, по которому является субъект персональных
        данных;
      </Text>
      <Text className={styles.Text}>
        <span className={styles.Label}>7.1.4.</span> В случае истечения срока хранения персональных данных,
        определяемого в соответствии с законодательством Российской Федерации.
      </Text>
      <Text className={styles.Text}>
        <span className={styles.Label}>7.2.</span> Согласие на обработку персональных данных может быть отозвано
        Пользователем в любой момент. Для реализации права на отзыв Пользователь должен направить соответствующее
        обращение на адрес <PageLink href={'mailto:' + emailAddress}>{emailAddress}</PageLink>.
      </Text>
    </Box>
  )
}

export default AgreementContent
