/** @module PageSectionHeader
 *  @desc Main page sections wrapper (provides referable id (as page anchor) and top margin for correct display under sticky page header).
 *  @since 2022.01.04, 20:38
 *  @changed 2022.01.04, 20:38
 */

import React from 'react'
import classnames from 'classnames'

import {
  // Button,
  Box,
  Flex,
  Heading,
  // Text,
} from '@chakra-ui/react'
import { TReactContent } from '@utils/react-types'

import styles from './PageSectionHeader.module.scss'
import cssParams from '@global/cssParams'

const titleSizes = {
  normal: ['xl', '2xl', '3xl', '4xl'],
  large: ['2xl', '3xl', '4xl', '5xl'],
}
const defaultTitleSize = Object.keys(titleSizes)[0]

interface TPageSectionHeaderProps {
  className?: string
  // children?: TReactContent
  title: TReactContent
  description?: TReactContent
  children?: TReactContent
  titleSize?: keyof typeof titleSizes
  isDark?: boolean
}

function PageSectionHeader(props: TPageSectionHeaderProps) {
  const {
    className,
    // children,
    title,
    description,
    children,
    titleSize = defaultTitleSize,
    isDark,
  } = props
  const titleSizeValues = titleSizes[titleSize]
  const hasDescr = !!(description || children)
  // const textColor = isDark ? '#fff' : cssParams.defaultTextColor
  const titleColor = isDark ? '#fff' : cssParams.primaryColor
  const descrContent = hasDescr && (
    <Box
      key="description"
      // fontSize="16px"
      mx="20px"
      // maxW="460px"
      textAlign="center"
    >
      {description || children}
    </Box>
  )
  const rootClassName = classnames(className, styles.root, isDark && styles.isDark)
  return (
    <Flex
      className={rootClassName}
      flexDirection="column"
      alignItems="center"
      mb="32px"
      // mt={['24', '24', '24', '24', '64']}
    >
      <Heading
        as="h3"
        // textTransform="uppercase"
        fontSize={titleSizeValues}
        fontWeight="normal"
        m="20px"
        textAlign="center"
        color={titleColor}
        // variant="primary"
        // color={textColor}
      >
        {title}
      </Heading>
      {descrContent}
    </Flex>
  )
}

export default PageSectionHeader
